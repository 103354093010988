.sd-element__header .svc-string-editor {
  display: inline-block;
  max-width: 100%;
}

.svc-string-editor {
  position: static;
}
.svc-string-editor [contenteditable=true] {
  user-select: text;
  -webkit-user-select: text;
}
.svc-string-editor .sv-string-editor {
  position: relative;
  outline: none;
  z-index: 12;
}
.svc-string-editor .sv-string-editor[aria-placeholder]:empty:before {
  content: attr(aria-placeholder);
  color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}
.svc-string-editor .sv-string-editor--html,
.svc-string-editor .sv-string-editor:focus-within {
  white-space: pre-wrap;
}
.svc-string-editor.svc-string-editor--hidden {
  display: none;
}

.svc-string-editor__content {
  display: inline-block;
  position: relative;
  z-index: 10;
  width: 100%;
}

.svc-matrix-cell .svc-string-editor__content {
  width: auto;
}

.svc-string-editor__content:focus-within .svc-remaining-character-counter {
  display: inline-block;
}

.svc-string-editor__input {
  display: flex;
}

.svc-remaining-character-counter {
  display: none;
  position: relative;
  outline: none;
  z-index: 20;
  padding: 0px;
  vertical-align: top;
  margin-inline-start: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  font-size: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  font-family: var(--sjs-font-family, var(--font-family));
  font-weight: 400;
  color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  line-height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-string-editor__border {
  display: none;
  position: absolute;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: right;
  line-height: 0px;
}

.svc-string-editor__error {
  margin-left: var(--sjs-base-unit, var(--base-unit, 8px));
  line-height: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  color: red;
  display: none;
}

.svc-string-editor__controls {
  position: absolute;
  left: 100%;
  height: 100%;
  width: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  top: 0;
  padding-left: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  z-index: 12;
  display: none;
  align-items: center;
  justify-content: center;
  line-height: 0px;
}

.svc-string-editor__button {
  display: none;
  position: relative;
}
.svc-string-editor__button use {
  fill: var(--sjs-primary-backcolor, var(--primary, #19b394));
}

.svc-string-editor__button--edit {
  height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  width: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding: var(--sjs-base-unit, var(--base-unit, 8px));
}
.svc-string-editor__button--edit .sv-svg-icon {
  vertical-align: bottom;
}
.svc-string-editor__button--edit.sv-svg-icon {
  overflow: visible;
}

.svc-string-editor:hover:not(:focus-within) .svc-string-editor__button--edit {
  display: none;
}

.svc-string-editor:hover:not(.svc-string-editor--readonly):not(:focus-within) .svc-string-editor__border {
  display: flex;
  top: 0;
  bottom: 0;
  left: calc(-0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  right: calc(-0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  border-radius: 3px;
  box-shadow: 0px 0px 0px calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) rgba(0, 0, 0, 0.16);
}

.svc-string-editor:focus-within .svc-string-editor__border {
  display: flex;
  top: calc(-0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  bottom: calc(-0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  left: calc(-1 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  right: calc(-1 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  border-radius: 3px;
  box-sizing: content-box;
  background-color: var(--sjs-general-backcolor, var(--background, #fff));
  box-shadow: var(--sjs-shadow-medium, 0px 2px 6px 0px rgba(0, 0, 0, 0.1)), 0px 0px 0px calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) var(--sjs-primary-backcolor, var(--primary, #19b394));
}

.svc-string-editor.svc-string-editor--error:focus-within .svc-string-editor__border {
  box-shadow: var(--sjs-shadow-medium, 0px 2px 6px 0px rgba(0, 0, 0, 0.1)), 0px 0px 0px calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) var(--sjs-special-red, var(--red, #e60a3e));
}

.sd-boolean .sv-string-editor:focus-within {
  white-space: nowrap;
}

.sd-rating .sd-rating__item:focus-within {
  border: calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) solid var(--sjs-primary-backcolor, var(--primary, #19b394));
}
.sd-rating .sd-rating__item:focus-within:not(.sd-rating__min-text) .svc-string-editor:focus-within .svc-string-editor__border, .sd-rating .sd-rating__item:focus-within:not(.sd-rating__max-text) .svc-string-editor:focus-within .svc-string-editor__border {
  display: none;
}

.sd-table__cell--actions .svc-string-editor__button--done,
.sd-matrixdynamic__add-btn .svc-string-editor__button--done {
  width: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sd-table__cell--column-title .svc-matrix-cell:not(:focus):focus-within .svc-matrix-cell--selected {
  border: calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) solid var(--sjs-primary-backcolor, var(--primary, #19b394));
  border-radius: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  display: block;
}
.sd-table__cell--column-title .svc-string-editor:focus-within .svc-string-editor__border {
  display: none;
}

.sd-item__control-label .svc-string-editor:focus-within .svc-string-editor__border {
  left: calc(-0.7 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  right: calc(-0.7 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-string-editor:hover ~ .sd-question__required-text, .svc-string-editor:focus-within ~ .sd-question__required-text {
  visibility: hidden;
}

.svc-string-editor--multiline .sv-string-editor {
  white-space: pre-wrap;
}