﻿@mixin main_m600_styles {
  .sv_main {
    .sv_container {
      .sv_body {
        .sv_p_root {
          .sv_row {
            .sv_q,
            .sv_qstn {
              display: block;
              width: 100% !important;

              .title-left {
                float: none;
              }

              .sv_q_radiogroup_inline,
              .sv_q_checkbox_inline,
              .sv_q_imagepicker_inline {
                display: block;
              }

              table.sv_q_matrix,
              table.sv_q_matrix_dropdown,
              table.sv_q_matrix_dynamic {
                display: block;

                thead {
                  display: none;
                }

                td.sv-table__cell--choice {
                  text-align: initial;
                }

                tbody,
                tr,
                td {
                  display: block;
                }
              }

              table.sv_q_matrix_dropdown,
              table.sv_q_matrix_dynamic {
                td {
                  &:before {
                    content: attr(data-responsive-title);
                  }
                }
                .sv-table__cell--row-text {
                  &::before {
                    content: none;
                  }
                }
              }

              table.sv_q_matrix {
                td {
                  label.sv_q_m_label {
                    display: inline;
                  }
                }

                .sv_q_m_cell {
                  text-align: initial;
                }
              }
            }
          }
        }
      }
    }
  }
}

.sv_m600 {
  @include main_m600_styles();
}

@media (max-width: 600px) {
  @include main_m600_styles();
}
