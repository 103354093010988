.Img_user_Data{
	border-radius: 50%;
	height: 35px;
	width: 35px;
	// border: 1px solid #000;
}
.svg-container:hover .arrow_icon {
	display: block;
	color: #E6E8E9;
  }
  .svg-container {
	position: relative;
  }
.rotate_icon{
	transform: rotate(180deg);
}
.add_border_bottom{
	border-bottom: 2px solid #0a7bac;
}
.arrow_icon {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	color: #E6E8E9;
  }

// table{
//   width: 100%;
//   margin: 0 auto;
//   border-collapse: collapse;
//   border: 1px solid #000;
// }

// /* Zebra stripping  */
// tr:nth-of-type(odd){
//   background: #FFFFFF;
// }
// tr{
// 	border-bottom: 1px solid #E6E8E9;
// }

// th{
//   background: #E6EFF4;
//   color: black;
//   width: auto;
// }
// td, th {
//   // border: 1px solid #ccc;
//   text-align: center;
// //   padding: 0px 0px 0px 5px;
// width: auto;
// }

// /* 
// Max width before this PARTICULAR table gets nasty
// This query will take effect for any screen smaller than 760px
// and also iPads specifically.
// */
// @media 
// only screen and (max-width: 760px),
// (min-device-width: 768px) and (max-device-width: 1024px)  {

// 	/* Force table to not be like tables anymore */
// 	table, thead, tbody, th, td, tr { 
// 		display: block; 
// 	}
	
// 	/* Hide table headers (but not display: none;, for accessibility) */
// 	thead tr { 
// 		position: absolute;
// 		top: -9999px;
// 		left: -9999px;
// 	}
	
// 	// tr { border: 1px solid #ccc; }
	
// 	td { 
// 		/* Behave  like a "row" */
// 		border: none;
// 		border-bottom: 1px solid #eee; 
// 		position: relative;
// 		padding-left: 50%; 
// 	}
	
// 	td:before { 
// 		/* Now like a table header */
// 		position: absolute;
// 		/* Top/left values mimic padding */
// 		top: 6px;
// 		left: 6px;
// 		width: 45%; 
// 		padding-right: 10px; 
// 		white-space: nowrap;
// 	}
	
// }
