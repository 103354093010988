@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Overpass:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap");

.activeDropdown {
  transition-duration: 2s;
  transition-timing-function: linear;
}

/* tailwind.css */

@layer utilities {
  .custom-css {
    flex: 0 1 auto;
    transition: all 2s ease-in-out;
  }

  .text-black {
    color: #000;
  }

  .custom-css:hover {
    flex: 1 0 auto;
  }

  .map-sub-heading {
    @apply mt-2 mb-4 ml-6 text-base font-semibold text-secondaryMid;
    @apply mt-2 mb-4 ml-6 text-base font-semibold text-secondaryMid;
  }

  .custom__container {
    @apply w-[95.5%] mx-auto;
  }
}

.css-1n6sfyn-MenuList {
  max-height: 200px !important;
}

.MuiCheckbox-indeterminate {
  color: #005c89 !important;
}

/* Tinymce Text Editor Custom Styling */
.tox-statusbar__branding,
.tox-notifications-container {
  display: none;
}

.tox-statusbar__help-text,
.tox-statusbar__path {
  visibility: hidden;
}

.no-scrollbar::-webkit-scrollbar {
  width: 0rem;
  background-color: transparent;
}

.no-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
  /* Hide the thumb (the draggable part) */
}

.svc-creator__banner {
  display: none !important;
}

body .tox .tox-notification--warn,
body .tox .tox-notification--warning {
  display: none !important;
  visibility: hidden;
  position: absolute;
  left: -9999px;
}

/* Custom styles for the popup content */
.mapboxgl-popup-content {
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Styling the popup close button */
.mapboxgl-popup-close-button {
  color: #777;
  font-size: 18px;
  position: absolute;
  top: 5px;
  right: 5px;
}

/* Styling the popup tip (arrow) */
.mapboxgl-popup-tip {
  border-top-color: #fff;
}

/* Styling the popup tip (arrow) when opened at the bottom */
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: transparent;
  border-bottom-color: #fff;
}

/* Styling the popup tip (arrow) when opened at the top */
/* .mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-top-color: #fff;
  border-bottom-color: transparent;
} */

.ps-menu-button:hover {
  background-color: #ebf2f6 !important;
}

.sign .ps-menu-button:hover {
  background-color: #F6EDDD !important;
}


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-moz-range-progress,
input[type="number"]::-moz-range-thumb {
  -moz-appearance: none;
}

input[type="number"]::-ms-clear {
  display: none;
}

::placeholder {
  text-transform: capitalize;
}

input[type="number"] {
  appearance: textfield;
}

/* 
input[type="date"]::-webkit-clear-button {
  display: none;
}

input[type="date"]::-moz-clear {
  display: none;
}

input[type="date"]::-ms-clear {
  display: none;
}

input[type="date"]::-clear-button {
  display: none;
}

input[type="date"]::-webkit-clear-button {
  display: none;
} */

/* Hide clear and spin buttons for date input */
input[type="date"]::-webkit-clear-button,
input[type="date"]::-webkit-inner-spin-button {
  display: none;
}

/* Add this CSS to your stylesheets */
.custom-tooltip .MuiTooltip-tooltip {
  background-color: white;
  /* Add any other tooltip styles you need */
}

.geography-map .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
  /* margin-bottom: 34px !important; */
}

.mapboxgl-popup-content {
  max-width: 280px !important;
  padding: 5px 20px !important;
}

.mapboxgl-ctrl-attrib,
.mapboxgl-ctrl-logo {
  display: none !important;
}

/* TABLE STYLES */

.Img_user_Data,
.Img_community_Data,
.Img_organization_Data {
  border-radius: 50%;
  height: 35px;
  width: 35px;
  /* // border: 1px solid #000; */
}

.svg-container .arrow_icon {
  display: block;
  color: #e6e8e9;
}

.svg-container {
  position: relative;
}

.rotate_icon {
  transform: rotate(180deg);
}

.add_border_bottom {
  border-bottom: 2px solid var(--primaryMid);
}

.arrow_icon {
  // display: none;
  position: absolute;
  top: 0;
  left: 0;
  color: #e6e8e9;
}

table {
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
}

// /* Zebra stripping  */
// tr:nth-of-type(odd) {
//   background: #FFFFFF;
// }

th {
  background: var(--primaryExtraLight) !important;
  color: black;
  width: auto;
}

td,
th {
  /* border: 1px solid #ccc; */
  text-align: center;
  /* padding: 0px 0px 0px 5px; */
  width: auto;
}

input[type="date"] {
  background: transparent;
  // color: white;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(100%);
}

/* .item_data:hover .item_hover_effect {
  background-color: #f2f7f9;
} */

@layer components {
  .my-react-select-container .my-react-select__control {
    @apply dark:bg-secondaryLight dark:border-none;
  }

  .my-react-select-container .my-react-select__control--is-focused {
    @apply dark:border-none shadow-none;
  }

  .my-react-select-container .my-react-select__menu {
    @apply dark:bg-secondaryLight dark:border-none;
  }

  .my-react-select-container .my-react-select__option {
    @apply dark:text-caption dark:hover:text-textMain dark:bg-secondaryLight dark:hover:bg-primaryDark;
  }

  .my-react-select-container .my-react-select__indicator-separator {
    @apply bg-primaryDark;
  }

  .my-react-select-container .my-react-select__input-container,
  .my-react-select-container .my-react-select__placeholder,
  .my-react-select-container .my-react-select__single-value {
    @apply dark:text-caption dark:hover:text-textMain;
  }

  .my-react-select-container .my-react-select__multi-value {
    @apply dark:bg-primary_40;
  }

  .my-react-select-container .my-react-select__multi-value__label {
    @apply dark:text-textMain;
  }

  .my-react-select-container .my-react-select__multi-value__remove {
    @apply dark:bg-textMain dark:hover:bg-primaryDark rounded-full;
  }

  .my-react-select-container .my-react-select__multi-value__remove .css-tj5bde-Svg {
    @apply dark:text-caption dark:hover:text-textMain;
  }

  .my-react-select-container .my-react-select__clear-indicator,
  .my-react-select-container .my-react-select__dropdown-indicator {
    @apply dark:text-caption dark:hover:text-textMain;
  }

  .my-react-select-container .my-react-select__clear-indicator:hover,
  .my-react-select-container .my-react-select__dropdown-indicator:hover {
    @apply dark:text-caption dark:hover:text-textMain;
  }

  .my-react-select-container .my-react-select__clear-indicator .css-tj5bde-Svg,
  .my-react-select-container .my-react-select__dropdown-indicator .css-tj5bde-Svg {
    @apply fill-caption;
  }

  /* custom-phone-input.css */
  .react-phone-number-input__input {
    @apply disabled:cursor-not-allowed w-full h-full focus:outline-none dark:bg-secondaryLight resize-none gap-1.5 px-3 py-2 dark:text-inputText dark:placeholder:text-inputText;
  }

  .PhoneInputInput {
    @apply disabled:cursor-not-allowed focus:outline-none dark:bg-secondaryLight dark:text-inputText dark:placeholder:text-inputText;
  }

  .PhoneInputInternationalIconPhone {
    @apply disabled:cursor-not-allowed dark:fill-caption;
  }

  .PhoneInputInternationalIconGlobe {
    @apply disabled:cursor-not-allowed dark:fill-caption;
  }

  .MuiOutlinedInput-input {
    @apply rounded-lg text-left dark:bg-secondaryLight p-2 dark:text-caption h-10 text-sm font-normal tracking-normal;
  }

  .MuiInputBase-root {
    @apply dark:bg-secondaryLight;
  }

  .MuiPaper-root {
    @apply bg-secondaryLight;
  }

  .MuiPaper-root.MuiPopover-paper.MuiMenu-paper {
    @apply bg-secondaryLight;
  }

  .apexcharts-yaxis-label {
    @apply dark:text-textMain;
  }

  // Directory Dark Mode Settings
  // .dark .MuiPopover-paper {
  //   background-color: transparent;
  // }

  table tr.item_data>td:not(:has(.status)) p {
    @apply dark:text-caption;
  }

  table tr.item_data:hover>td>div:not(.status) p {
    @apply dark:text-secondary;
  }

  table tr.item_data:hover>td:not(.status) p {
    @apply dark:text-secondary;
  }

  table tr.item_data:hover>td>a>div:not(.status) p {
    @apply dark:text-secondary;
  }

  table tr.item_data:hover>td>a:hover>div:not(.status) p {
    @apply dark:text-primary;
  }

  .table_row_header>tr {
    @apply dark:border-none;
  }

  table tr.item_data:hover .MuiSelect-select {
    @apply dark:bg-bgHighMidlight;
  }

  .MuiDateCalendar-root {
    @apply dark:bg-secondaryLight;
  }

  .calender-icon path {
    @apply dark:fill-caption fill-secondary;
  }

  .MuiRadio-root svg {
    @apply dark:text-caption;
  }

  .custom-html-content a {
    @apply text-primary;
  }

  .custom-htm-content b {
    @apply text-sm font-semibold text-left text-secondaryMid;
  }

  .PhoneInputCountrySelect {
    @apply dark:bg-secondaryLight dark:text-caption;
  }
}

.css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: transparent !important;
}

.PhoneInputCountrySelect {
  max-height: 200px !important;
  overflow-y: auto;
}

.dark .PhoneInputCountrySelect {
  background: theme("colors.secondaryLight");
}

.dark .PhoneInputCountrySelect option {
  color: theme("colors.caption");
}

.dark .MuiDateCalendar-root {
  background: theme("colors.secondaryLight");
}

:not(.dark) table tr.item_data:hover,
:not(.dark) table tr.item_data:hover>td.item_hover_effect {
  background: theme("colors.bgHighMidlight");
}

// .dark .MuiList-root {
//   background: theme("colors.secondaryLight");
// }
// .dark .MuiDayCalendar-weekDayLabel{
//   color: theme("colors.caption");
// }
// .dark .MuiPickersDay-root:not(.Mui-selected){
//   color: theme("colors.textMain");
// }
// .dark .MuiPickersYear-yearButton , .MuiPickersCalendarHeader-label {
//   color: theme("colors.caption");
// }

// .dark .MuiList-root .MuiMenuItem-root {
//   color: theme("colors.caption") !important;
// }

// .dark .MuiList-root .MuiMenuItem-root:hover {
//   background: theme("colors.bgBluish_2");
//   color: theme("colors.secondary") !important;
// }

// .dark .MuiPopover-paper {
//   background: theme("colors.secondaryLight");
// }
.dark {

  .MuiDayCalendar-weekDayLabel,
  .MuiPickersYear-yearButton,
  .MuiPickersCalendarHeader-label {
    color: theme("colors.caption");
  }

  .MuiPickersCalendarHeader-switchViewIcon,
  .MuiSvgIcon-root {
    fill: theme("colors.caption");
  }

  .MuiPickersDay-root:not(.Mui-selected) {
    border: theme("colors.lineLight");
  }

  .MuiPopover-paper {
    background: theme("colors.secondaryLight");
  }

  // List styles
  .MuiList-root {
    background: theme("colors.secondaryLight");

    // MenuItem styles
    .MuiMenuItem-root {
      color: theme("colors.caption") !important;

      &:hover {
        background: theme("colors.bgBluish_2");
        color: theme("colors.secondary") !important;
      }
    }
  }

  // MuiPickersDay styles
  .MuiPickersDay-root:not(.Mui-selected) {
    color: theme("colors.textMain");
  }
}

.dark tr:hover .MuiInputBase-root {
  background: none;
}

.tailspin-loader circle {
  fill: none;
}

input[type="search"]::-webkit-search-cancel-button {
  cursor: pointer !important;
}

input[type="search"]::-ms-clear {
  cursor: pointer !important;
}

.observerDropdown .MuiSelect-select {
  background: none;
}

// Date Input field
.input-date-wrapper .input-date[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

// Radio Button
.dark .MuiRadio-root svg:first-child {
  color: theme("colors.textLight");
}

.my-react-select__option--is-selected:active {
  background-color: theme("colors.primaryLight") !important;
  /* Set to 'transparent' or any desired color */
}

.tooltip-arrow {
  position: absolute;
  top: -13px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: auto;
}

/* Adjust zoom level for screen widths between 10in and 11.9in */
@media only screen and (min-width: 10in) and (max-width: 11.9in) {
  .dashboard-widgets {
    zoom: 0.63;
  }
}

/* Adjust zoom level for screen widths between 12in and 12.9in */
@media only screen and (min-width: 12in) and (max-width: 12.9in) {
  .dashboard-widgets {
    zoom: 0.79;
  }
}

/* Adjust zoom level for screen widths between 13in and 13.9in */
@media only screen and (min-width: 13in) and (max-width: 13.9in) {
  .dashboard-widgets {
    zoom: 0.87;
  }
}

/* Adjust zoom level for screen widths between 14in and 14.9in */
@media only screen and (min-width: 14in) and (max-width: 14.9in) {
  .dashboard-widgets {
    zoom: 0.96;
  }
}

/* Adjust zoom level for screen widths between 15in and 15.9in */
@media only screen and (min-width: 15in) and (max-width: 15.9in) {
  .dashboard-widgets {
    zoom: 0.99;
  }
}

// .dark .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
//   border-color: theme('colors.textLight');
//   opacity: .2;
// }

// /* CSS variables. */
// :root {
//   --PhoneInput-color--focus: #03b2cb;
//   --PhoneInputInternationalIconPhone-opacity: 0.8;
//   --PhoneInputInternationalIconGlobe-opacity: 0.65;
//   --PhoneInputCountrySelect-marginRight: 0.35em;
//   --PhoneInputCountrySelectArrow-width: 0.3em;
//   --PhoneInputCountrySelectArrow-marginLeft: var(
//     --PhoneInputCountrySelect-marginRight
//   );
//   --PhoneInputCountrySelectArrow-borderWidth: 1px;
//   --PhoneInputCountrySelectArrow-opacity: 0.45;
//   --PhoneInputCountrySelectArrow-color: currentColor;
//   --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
//   --PhoneInputCountrySelectArrow-transform: rotate(45deg);
//   --PhoneInputCountryFlag-aspectRatio: 1.5;
//   --PhoneInputCountryFlag-height: 1em;
//   --PhoneInputCountryFlag-borderWidth: 1px;
//   --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
//   --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
//   --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
// }

// .PhoneInput {
//   /* This is done to stretch the contents of this component. */
//   display: flex;
//   align-items: center;
// }

// .PhoneInputInput {
//   /* The phone number input stretches to fill all empty space */
//   flex: 1;
//   /* The phone number input should shrink
// 	   to make room for the extension input */
//   min-width: 0;
// }

// .PhoneInputCountryIcon {
//   width: calc(
//     var(--PhoneInputCountryFlag-height) *
//       var(--PhoneInputCountryFlag-aspectRatio)
//   );
//   height: var(--PhoneInputCountryFlag-height);
// }

// .PhoneInputCountryIcon--square {
//   width: var(--PhoneInputCountryFlag-height);
// }

// .PhoneInputCountryIcon--border {
//   /* Removed `background-color` because when an `<img/>` was still loading
// 	   it would show a dark gray rectangle. */
//   /* For some reason the `<img/>` is not stretched to 100% width and height
// 	   and sometime there can be seen white pixels of the background at top and bottom. */
//   background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
//   /* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
//   /* For some reason the `<img/>` is not stretched to 100% width and height
// 	   and sometime there can be seen white pixels of the background at top and bottom,
// 	   so an additional "inset" border is added. */
//   box-shadow:
//     0 0 0 var(--PhoneInputCountryFlag-borderWidth)
//       var(--PhoneInputCountryFlag-borderColor),
//     inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
//       var(--PhoneInputCountryFlag-borderColor);
// }

// .PhoneInputCountryIconImg {
//   /* Fixes weird vertical space above the flag icon. */
//   /* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
//   display: block;
//   /* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
// 	   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
//   width: 100%;
//   height: 100%;
// }

// .PhoneInputInternationalIconPhone {
//   opacity: var(--PhoneInputInternationalIconPhone-opacity);
// }

// .PhoneInputInternationalIconGlobe {
//   opacity: var(--PhoneInputInternationalIconGlobe-opacity);
// }

// /* Styling native country `<select/>`. */

// .PhoneInputCountry {
//   position: relative;
//   align-self: stretch;
//   display: flex;
//   align-items: center;
//   margin-right: var(--PhoneInputCountrySelect-marginRight);
// }

// .PhoneInputCountrySelect {
//   position: absolute;
//   top: 0;
//   left: 0;
//   height: 100%;
//   width: 100%;
//   z-index: 1;
//   border: 0;
//   opacity: 0;
//   cursor: pointer;
// }

// .PhoneInputCountrySelect[disabled],
// .PhoneInputCountrySelect[readonly] {
//   cursor: default;
// }

// .PhoneInputCountrySelectArrow {
//   display: block;
//   content: "";
//   width: var(--PhoneInputCountrySelectArrow-width);
//   height: var(--PhoneInputCountrySelectArrow-width);
//   margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
//   border-style: solid;
//   border-color: var(--PhoneInputCountrySelectArrow-color);
//   border-top-width: 0;
//   border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
//   border-left-width: 0;
//   border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
//   transform: var(--PhoneInputCountrySelectArrow-transform);
//   opacity: var(--PhoneInputCountrySelectArrow-opacity);
// }

// .PhoneInputCountrySelect:focus
//   + .PhoneInputCountryIcon
//   + .PhoneInputCountrySelectArrow {
//   opacity: 1;
//   color: var(--PhoneInputCountrySelectArrow-color--focus);
// }

// .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
//   box-shadow:
//     0 0 0 var(--PhoneInputCountryFlag-borderWidth)
//       var(--PhoneInputCountryFlag-borderColor--focus),
//     inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
//       var(--PhoneInputCountryFlag-borderColor--focus);
// }

// .PhoneInputCountrySelect:focus
//   + .PhoneInputCountryIcon
//   .PhoneInputInternationalIconGlobe {
//   opacity: 1;
//   color: var(--PhoneInputCountrySelectArrow-color--focus);
// }

// .dark table tr.item_data:hover,
// .dark table tr.item_data:hover > td.item_hover_effect {
//   background: #F2F7F9;
// }
.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 7.5px 14px !important;
}

.qaqc-comment .tox-tinymce {
  // resize: vertical;
  overflow: auto;
  min-width: 200px;
  min-height: 50px;
  max-width: 100%;
  max-height: 100%;
  padding-right: 1.2rem;
}

.css-13cymwt-control {
  border-width: 0 !important;
}

.comment-activity a {
  font-style: normal !important;
}

.yarl__slide_image {
  min-width: 500px;
}

// Table text work break

table td p.break-all {
  word-break: break-word !important;
}

.lat-lng-fields .MuiOutlinedInput-root {
  height: 37px;
  line-height: 1.8;
}

.lat-lng-fields .MuiSelect-select {
  text-overflow: unset !important;
  padding-right: 0 !important;
}

// Observation activities
.comment-activity a:hover {
  color: theme("colors.primaryMid") !important;
}

.qaqc-comment {
  border: 2px solid #eee;
  border-radius: 10px;
  max-height: 80px;
  overflow-y: auto;
  padding-right: 1.5rem;
}

// Survey js overflow added if content gets out of container
.svc-question__content .sd-selectbase[role="listbox"],
.svc-question__content .sd-selectbase[role="radiogroup"] {
  overflow-x: auto !important;
}

.spg-question[data-name="checkboxLayoutText"] .spg-comment__content {
  display: none;
}