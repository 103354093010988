.ChevronRightIconHover {
  position: absolute;
  top: 1.5rem;
  left: -9px;
  border-radius: 100%;
  height: 23px;
  width: 23px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #f5f5f5;
}
.ChevronRightIconHover:hover .hoverEffect {
  color: white;
  fill: #ffffff;
}

// .ChevronRightIconHover:hover {
//   background-color: #005c89;
//   border: none;
// }
