.main-popup .mapboxgl-popup-content {
  width: 160px !important;
  border-radius: 8px !important;
  border: 0px solid #000 !important;
  height: 52px;
}
.obsCode-heading {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}

.date-created {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  margin-top: 5px;
}
