.spg-panel__title {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  width: 100%;
  padding: calcSize(2);
  border: none;
  outline: none;
  color: $foreground-light;
  cursor: pointer;
  text-align: left;
  background-color: $background;
  box-shadow: inset 0px -1px 0px $border;
  font-family: $font-family;
  font-size: calcSize(2);
  font-weight: 400;
  margin: 0;
}

.spg-panel__title:disabled,
.spg-panel__title:disabled:hover {
  color: $foreground;
  opacity: 0.25;
  cursor: default;
}

.spg-panel__title--collapsed:hover,
.spg-panel__title--expandable:focus {
  background-color: $background-dim;
}

.spg-panel__title--expanded {
  color: $foreground;
  font-weight: 600;
}

.spg-panel__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0 calcSize(4) calcSize(5);
  box-sizing: border-box;
  background: $background-dim;
  box-shadow: inset 0px -1px 0px $border;
}

.spg-panel__content {
  .spg-row {
    width: 100%;
    margin-top: calcSize(2);
  }

  .spg-row--multiple {
    margin-top: calcSize(1);
  }
}

.sv-mobile-side-bar {
  .spg-panel__content {
    padding: 0 calcSize(2) calcSize(5);
  }
}

.spg-question__nopadding {
  .spg-panel__content {
    padding: 0px;
    box-shadow: unset;
  }
}