.svc-item-value-wrapper {
  position: relative;
  display: flex;
  gap: var(--sjs-base-unit, var(--base-unit, 8px));
  align-items: center;
  margin-left: calc(-5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-question__dropdown-choice .svc-item-value-wrapper,
.sd-selectbase .svc-item-value-wrapper {
  align-items: flex-start;
}
.svc-question__dropdown-choice .svc-item-value__item,
.sd-selectbase .svc-item-value__item {
  padding-right: var(--sjs-base-unit, var(--base-unit, 8px));
}

.svc-question__content .sd-selectbase__column {
  margin-left: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  min-width: initial;
}
.svc-question__content .sd-selectbase--mobile .sd-selectbase__column,
.svc-question__content .sd-selectbase__column:first-of-type,
.svc-question__content .sd-imagepicker__column {
  margin-left: 0;
}
.svc-question__content .sd-selectbase__column:not(:last-child) {
  padding-right: 0;
}

.svc-item-value--dragging {
  background: var(--sjs-general-backcolor, var(--background, #fff));
  box-shadow: var(--sjs-shadow-large, 0px 8px 16px 0px rgba(0, 0, 0, 0.1));
  border-radius: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-right: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-left: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.svc-item-value-controls {
  display: flex;
  margin: var(--sjs-base-unit, var(--base-unit, 8px)) 0;
  height: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  box-sizing: border-box;
  flex-shrink: 0;
  min-width: 72px;
  justify-content: flex-end;
}

.svc-item-value-controls__button {
  border-radius: 50%;
  padding: var(--sjs-base-unit, var(--base-unit, 8px));
  cursor: pointer;
  display: inline-block;
  outline: none;
  height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  box-sizing: content-box;
}
.svc-item-value-controls__button .sv-svg-icon {
  display: block;
}

.svc-item-value-controls__button > span {
  display: block;
}

.svc-item-value-controls__drag-icon {
  display: block;
}

.svc-item-value-wrapper:hover:not(.svc-item-value--ghost) .svc-item-value-controls__drag-icon,
.svc-item-value-controls__drag:hover:not(.svc-item-value--ghost) .svc-item-value-controls__drag-icon {
  visibility: visible;
}

.svc-item-value-controls__drag {
  padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) var(--sjs-base-unit, var(--base-unit, 8px));
  cursor: move;
  opacity: 0.25;
}
.svc-item-value-controls__drag .svc-item-value-controls__drag-icon {
  visibility: hidden;
}
.svc-item-value-controls__drag use {
  fill: var(--sjs-general-forecolor, var(--foreground, #161616));
}

.svc-item-value-controls__button--disabled {
  fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  opacity: 0.25;
  cursor: default;
}

.svc-item-value-controls__remove:not(.svc-item-value-controls__button--disabled) use {
  fill: var(--sjs-special-red, var(--red, #e60a3e));
}

.svc-item-value-controls__add:not(.svc-item-value-controls__button--disabled) use {
  fill: var(--sjs-primary-backcolor, var(--primary, #19b394));
}

.svc-item-value-controls__add:not(.svc-item-value-controls__button--disabled):hover, .svc-item-value-controls__add:not(.svc-item-value-controls__button--disabled):focus {
  background-color: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
}

.svc-item-value-controls__remove:not(.svc-item-value-controls__button--disabled):hover, .svc-item-value-controls__remove:not(.svc-item-value-controls__button--disabled):focus {
  background-color: var(--sjs-special-red-light, var(--red-light, rgba(230, 10, 62, 0.1)));
}

.sv-string-editor {
  cursor: text;
}

.svc-item-value--new .svc-item-value__item .sd-item__control-label,
.svc-item-value--new .svc-item-value__item .sv-ranking-item__text {
  color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}
.svc-item-value--new .svc-item-value__item .sd-item__decorator {
  opacity: 0.35;
}
.svc-item-value--new .sv-ranking-item__index {
  background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
}
.svc-item-value--new .sv-ranking-item__index svg {
  fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}

.sv-ranking .svc-item-value-wrapper {
  align-items: center;
}
.sv-ranking .svc-item-value__item {
  padding-right: 0;
  flex-grow: 1;
}

.svc-question__content .svc-item-value__item .sv-ranking-item__text {
  flex-grow: 1;
  width: 0;
  max-width: fit-content;
}

.svc-item-value__item .sv-ranking-item__content {
  padding-left: 0;
}
.svc-item-value__item .sv-ranking-item__icon-container {
  display: none;
}
.svc-item-value__item .sv-ranking-item__text {
  overflow: visible;
}
.svc-item-value__item .svc-string-editor {
  width: 100%;
  display: inline-block;
}
.svc-item-value__item .sv-string-editor {
  text-overflow: ellipsis;
  overflow: hidden;
}

.svc-item-value--ghost .svc-item-value__ghost {
  display: block;
}
.svc-item-value--ghost .svc-item-value-controls {
  visibility: hidden;
}

.svc-item-value--movedown {
  transform: translate(0, 0);
  animation: svdragdropmovedown 0.1s;
  animation-timing-function: ease-in-out;
}

@keyframes svdragdropmovedown {
  0% {
    transform: translate(0, -50px);
  }
  100% {
    transform: translate(0, 0);
  }
}
.svc-item-value--moveup {
  transform: translate(0, 0);
  animation: svdragdropmoveup 0.1s;
  animation-timing-function: ease-in-out;
}

@keyframes svdragdropmoveup {
  0% {
    transform: translate(0, 50px);
  }
  100% {
    transform: translate(0, 0);
  }
}
.svc-item-value__ghost {
  display: none;
  background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  border-radius: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  width: calc(25 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: 40px;
  z-index: 11;
  position: absolute;
  left: 35px;
}

.svc-creator--mobile .svc-tab-designer .svc-question__content--ranking [data-sv-drop-target-item-value] .svc-item-value-controls,
.svc-creator--mobile .svc-tab-designer .sd-selectbase [data-sv-drop-target-item-value] .svc-item-value-controls,
.svc-creator--mobile .svc-tab-designer .svc-question__dropdown-choices [data-sv-drop-target-item-value] .svc-item-value-controls {
  min-width: initial;
}
.svc-creator--mobile .svc-tab-designer .svc-question__content--ranking:not([data-sv-drop-target-item-value]) .svc-item-value-controls,
.svc-creator--mobile .svc-tab-designer .sd-selectbase:not([data-sv-drop-target-item-value]) .svc-item-value-controls,
.svc-creator--mobile .svc-tab-designer .svc-question__dropdown-choices:not([data-sv-drop-target-item-value]) .svc-item-value-controls {
  min-width: 64px;
}
.svc-creator--mobile .svc-tab-designer .svc-question__content--ranking .svc-item-value-controls__drag,
.svc-creator--mobile .svc-tab-designer .sd-selectbase .svc-item-value-controls__drag,
.svc-creator--mobile .svc-tab-designer .svc-question__dropdown-choices .svc-item-value-controls__drag {
  padding-right: 0;
}
.svc-creator--mobile .svc-tab-designer .svc-question__content--ranking .svc-item-value-controls__drag-icon,
.svc-creator--mobile .svc-tab-designer .sd-selectbase .svc-item-value-controls__drag-icon,
.svc-creator--mobile .svc-tab-designer .svc-question__dropdown-choices .svc-item-value-controls__drag-icon {
  visibility: visible;
}
.svc-creator--mobile .svc-tab-designer .svc-question__content--ranking .svc-item-value-wrapper,
.svc-creator--mobile .svc-tab-designer .sd-selectbase .svc-item-value-wrapper,
.svc-creator--mobile .svc-tab-designer .svc-question__dropdown-choices .svc-item-value-wrapper {
  margin-left: calc(-3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-creator--touch:not(.svc-creator--mobile) .svc-tab-designer .svc-question__content--ranking .svc-item-value-controls__drag-icon,
.svc-creator--touch:not(.svc-creator--mobile) .svc-tab-designer .sd-selectbase .svc-item-value-controls__drag-icon,
.svc-creator--touch:not(.svc-creator--mobile) .svc-tab-designer .svc-question__dropdown-choices .svc-item-value-controls__drag-icon {
  visibility: visible;
}
.svc-creator--touch:not(.svc-creator--mobile) .svc-tab-designer .svc-question__content--ranking .svc-item-value-wrapper,
.svc-creator--touch:not(.svc-creator--mobile) .svc-tab-designer .sd-selectbase .svc-item-value-wrapper,
.svc-creator--touch:not(.svc-creator--mobile) .svc-tab-designer .svc-question__dropdown-choices .svc-item-value-wrapper {
  margin-left: calc(-6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.svc-creator--touch:not(.svc-creator--mobile) .svc-tab-designer .svc-question__content--ranking .svc-item-value-controls__drag,
.svc-creator--touch:not(.svc-creator--mobile) .svc-tab-designer .sd-selectbase .svc-item-value-controls__drag,
.svc-creator--touch:not(.svc-creator--mobile) .svc-tab-designer .svc-question__dropdown-choices .svc-item-value-controls__drag {
  padding-left: 0;
  padding-right: 0;
}