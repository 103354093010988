.field-input {
  &::placeholder {
    font-weight: bold;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  // color: rgba(0, 0, 0, 0);
  opacity: 0;
  display: block;
  // background: url(https://mywildalberta.ca/images/GFX-MWA-Parks-Reservations.png) no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
}
