.sl-table {
  width: 50%;
  margin: 0 25%;
}

.sl-table__row {
  position: relative;
}

.sl-table__cell.st-table__cell--actions:first-of-type .sv-action-bar {
  margin-top: calc(-0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-right: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sl-table .sl-table__detail-button.sl-table__detail-button {
  width: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  box-sizing: border-box;
  border-radius: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding: 0;
}
.sl-table .sl-table__detail-button.sl-table__detail-button:hover:enabled, .sl-table .sl-table__detail-button.sl-table__detail-button.sv-focused--by-key {
  background-color: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
  outline: none;
}
.sl-table .sl-table__detail-button.sl-table__detail-button:hover:enabled use, .sl-table .sl-table__detail-button.sl-table__detail-button.sv-focused--by-key use {
  fill: var(--sjs-primary-backcolor, var(--primary, #19b394));
}

.sl-table__remove-button .sv-action-bar-item {
  border-radius: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding: var(--sjs-base-unit, var(--base-unit, 8px));
  cursor: pointer;
}
.sl-table__remove-button .sv-action-bar-item:hover:enabled, .sl-table__remove-button .sv-action-bar-item.sv-focused--by-key {
  background-color: var(--sjs-special-red-light, var(--red-light, rgba(230, 10, 62, 0.1)));
  outline: none;
}
.sl-table__remove-button .sv-action-bar-item:hover:enabled use, .sl-table__remove-button .sv-action-bar-item.sv-focused--by-key use {
  fill: var(--sjs-special-red, var(--red, #e60a3e));
}

.sl-table__cell .sv-action-bar-item__icon {
  display: none;
}

.sl-table__row:hover .sl-table__cell .sv-action-bar-item__icon,
.sl-table__cell .sv-action-bar-item.sv-focused--by-key .sv-action-bar-item__icon {
  display: block;
}

.sl-table__row #remove-row .sv-action-bar-item {
  border: none;
  width: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sl-table__row #remove-row .sv-action-bar-item__icon use {
  fill: var(--sjs-special-red, var(--red, #e60a3e));
}

.sl-table__row.sl-table__row--additional .sl-table__cell .svc-action-button {
  color: var(--sjs-secondary-backcolor, var(--secondary, #ff9814));
}

.sl-table__cell .svc-action-button,
.sl-table__cell .svc-action-button:hover,
.sl-table__cell .svc-action-button:focus,
.sl-table__cell .svc-action-button:hover:enabled,
.sl-table__cell .svc-action-button:focus:enabled {
  --medium-bold-font-size: var(--sjs-base-unit, var(--base-unit, 8px));
  font-family: var(--sjs-font-family, var(--font-family));
  font-style: normal;
  font-weight: bold;
  font-size: calc(3 * var(--medium-bold-font-size));
  line-height: calc(4 * var(--medium-bold-font-size));
  background: transparent;
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  outline: none;
  border: none;
  box-shadow: none;
  max-height: calc(13.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.sl-table__cell {
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  font-weight: bold;
  font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  font-family: var(--sjs-font-family, var(--font-family));
  vertical-align: top;
  padding: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))) 0;
  width: 100%;
}

.sl-table__cell--actions {
  position: absolute;
  width: max-content;
}
.sl-table__cell--actions:first-of-type {
  transform: translateX(-100%);
}

.sl-table__cell--detail-button {
  overflow: hidden;
  appearance: none;
  outline: none;
  border: none;
  background-color: transparent;
}

.sl-table {
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
}
.sl-table thead th:first-child {
  position: absolute;
  left: 0;
  transform: translateX(-100%);
}
.sl-table thead th:last-child {
  position: absolute;
  right: 0;
  transform: translateX(100%);
}
.sl-table thead .sl-table__cell.st-table__cell--header {
  text-align: left;
}
.sl-table tbody tr:last-of-type .sl-table__cell {
  padding-bottom: calc(10 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sl-table tr:first-of-type .sl-table__cell {
  padding-top: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sl-table td:first-of-type,
.sl-table th:first-of-type {
  padding-left: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sl-table td:first-of-type .sv-action-bar,
.sl-table th:first-of-type .sv-action-bar {
  justify-content: flex-end;
}
.sl-table td:last-of-type,
.sl-table th:last-of-type {
  padding-right: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sl-panel .sl-panel__footer {
  padding: 0;
}
.sl-panel .sl-panel__footer .sv-action {
  width: 100%;
}
.sl-panel .sl-panel__footer .sv-action__content {
  width: 100%;
}
.sl-panel .sl-panel__footer button.sl-panel__done-button {
  width: 100%;
  margin: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))) 0 calc(10 * (var(--sjs-base-unit, var(--base-unit, 8px)))) 0;
  color: var(--sjs-primary-backcolor, var(--primary, #19b394));
  background-color: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
  font-family: var(--sjs-font-family, var(--font-family));
  font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  border-radius: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  display: flex;
  align-items: center;
  vertical-align: baseline;
  text-align: center;
  user-select: none;
}
.sl-panel .sl-panel__footer button:hover:enabled {
  background-color: var(--sjs-primary-backcolor, var(--primary, #19b394));
  color: var(--sjs-primary-forecolor, var(--primary-foreground, #fff));
}
.sl-panel .sl-panel__footer .sv-action-bar-item__title {
  color: inherit;
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  font-weight: 600;
}

.sl-element .sd-paneldynamic__buttons-container {
  padding: 0;
}

.sl-question {
  display: flex;
  align-items: center;
  margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sl-row {
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
}

.sl-row--multiple .sl-panel {
  padding-bottom: 0;
}

.sl-question__header--left {
  margin-right: var(--sjs-base-unit, var(--base-unit, 8px));
}

.sl-row .sd-scrollable-container:not(.sd-scrollable-container--compact) {
  overflow-x: auto;
  padding: 2px;
  margin: -2px;
}

.sl-question__title {
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin: 0;
  font-weight: 600;
}
.sl-question__title span + span {
  display: none;
}

.sl-row--multiple .sl-question {
  padding-right: var(--sjs-base-unit, var(--base-unit, 8px));
}

.svc-logic-question-value {
  --sd-base-padding: calc(5 * var(--sjs-base-unit, var(--base-unit, 8px)));
  --sd-base-vertical-padding: calc(4 * var(--sjs-base-unit, var(--base-unit, 8px)));
  --sd-page-vertical-padding: calc(3 * var(--sjs-base-unit, var(--base-unit, 8px)));
  padding-right: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-logic_trigger-editor {
  margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-logic_trigger-editor .svc-logic-question-value {
  margin-top: 0;
}

.sv-popup.sv-property-editor .sl-row {
  flex-flow: nowrap;
}

.spg-comment.sl-comment {
  height: calc(17 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sl-body .sd-row--fade-out,
.sl-body .sd-row--fade-in {
  animation: none;
}
.sl-body .sd-element-wrapper--fade-out,
.sl-body .sd-element-wrapper--fade-in {
  animation: none;
}
.sl-body .sd-element__content--fade-in,
.sl-body .sd-element__content--fade-out {
  animation: none;
}
.sl-body .sd-paneldynamic__panel-wrapper--fade-in,
.sl-body .sd-paneldynamic__panel-wrapper--fade-out,
.sl-body .sd-paneldynamic__panel-wrapper--fade-out-left,
.sl-body .sd-paneldynamic__panel-wrapper--fade-out-right,
.sl-body .sd-paneldynamic__panel-wrapper--fade-in-left,
.sl-body .sd-paneldynamic__panel-wrapper--fade-in-right {
  animation: none;
}
.sl-body .sd-table__row--fade-out,
.sl-body .sd-table__row--fade-in {
  animation: none;
}