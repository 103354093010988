// .carousel .slide img {
//   width: 400px; /* Fixed width */
//   height: 400px; /* Fixed height */
//   object-fit: cover; /* This makes sure that images cover the area, you can change it to 'contain' if you don't want images to be cropped */
// }
// .carousel-container {
//   width: 600px; /* Fixed width of the carousel */
//   margin: auto; /* Center the carousel if needed */
// }

.box {
  width: 640px;
}

.nav_btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #fff;
  z-index: 4;
  border: none;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.nav_btn:hover,
.nav_btn:focus {
  outline: none;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

.nav_btn_left {
  left: 5px;
}
.nav_btn_right {
  right: 5px;
}

.ind.active {
  background-color: #0a7bac;
  transition: 500ms 200ms;
}

.ind:not(:last-child) {
  margin-right: 1.4rem;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 2px solid #0a7bac;
}
