.svd-simulator {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: content-box;
  transform-origin: 0 0;
  top: 50%;
  left: 50%;
  transform: scale(1.26) translate(-50%, -50%);
  overflow: overlay;
  border: 2px solid var(--sjs-border-inside, var(--border-inside, rgba(0, 0, 0, 0.16)));
}

.svd-simulator-wrapper {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  border-radius: 32px;
  border: 4px solid var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  background: var(--sjs-general-backcolor, var(--background, #fff));
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.1), 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
}

.svd-simulator-main {
  min-height: 100%;
}
.svd-simulator-main.svd-simulator-main--desktop {
  min-height: auto;
}
.svd-simulator-main.svd-simulator-main--desktop.svd-simulator-main--running {
  height: 100%;
}
.svd-simulator-main.svd-simulator-main--frame {
  display: flex;
  align-items: center;
}
.svd-simulator-main:not(.svd-simulator-main--frame) {
  background-color: transparent;
}
.svd-simulator-main .sd-root-modern {
  min-height: 100%;
}
.svd-simulator-main .sd-root-modern .sv-popup:not(.sv-popup--overlay):not(.sv-popup--modal) {
  width: auto;
  right: 0;
}

.svd-simulator-content {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-shadow: inset 0px -1px 0px 0px var(--sjs-border-inside, var(--border-inside, rgba(0, 0, 0, 0.16)));
  position: relative;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  ::i-block-chrome,
  .svd-simulator-content {
    overflow-y: scroll;
  }
}