.mentions {
    margin: 0;
}

.mentions--singleLine .mentions__control {
    display: inline-block;
    width: 130px;
}

.mentions--singleLine .mentions__highlighter {
    padding: 1px;
    border: 2px inset transparent;
}

.mentions--singleLine .mentions__input {
    padding: 1px;
    /* border: 2px inset; */
}

.mentions--multiLine .mentions__control {
    font-family: "Overpass";
    font-size: 1rem;
}

.mentions--multiLine .mentions__highlighter {
    border: 1px solid transparent;
    padding: 9px;
    min-height: 63px;
}

.mentions--multiLine .mentions__input {
    /* border: 1px solid silver; */
    padding: 9px;
    outline: 0;
}

.mentions--multiLine .mentions__input::placeholder{
    text-transform: none;
}

.mentions__suggestions__list {
    background: #fff;
    font-size: 1rem;
    z-index: 999;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    max-height: 130px;
    width: 180px;
    overflow-y: auto;
    border-radius: 5px;
}

.mentions__suggestions__item {
    padding: 5px 15px;
    color: #005C89;
}

.mentions__suggestions__item--focused {
    background-color: #cee4e5;
}

.mentions__mention {
    position: relative;
    z-index: 1;
    color: #005C89;
    text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
        -1px -1px 1px white;
    pointer-events: none;
}