.svc-question-link__set-button {
  font-weight: 600;
  cursor: pointer;
}

.svc-link-value-button {
  margin-left: calc(-2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-question-link__clear-button {
  color: var(--sjs-special-red, var(--red, #e60a3e));
  font-weight: 600;
  cursor: pointer;
}

.svc-question-link__clear-button:focus,
.svc-question-link__clear-button:hover {
  background-color: var(--sjs-special-red-light, var(--red-light, rgba(230, 10, 62, 0.1)));
}