svc-page-navigator-item,
.svc-page-navigator-item {
  display: block;
  width: calc(5.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(4.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  min-height: calc(4.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  cursor: pointer;
  position: relative;
}

.svc-page-navigator-item-content {
  height: 100%;
  width: 100%;
}
.svc-page-navigator-item-content:hover, .svc-page-navigator-item-content:focus {
  outline: none;
}

.svc-page-navigator__items--up .svc-page-navigator-item-content {
  transition: 0.25s ease-in-out;
  transform: translateY(-100%);
}
.svc-page-navigator__items--up svc-page-navigator-item:first-child .svc-page-navigator-item-content {
  opacity: 0;
}

.svc-page-navigator__items--down .svc-page-navigator-item-content {
  transition: 0.25s ease-in-out;
  transform: translateY(100%);
}
.svc-page-navigator__items--down svc-page-navigator-item:last-child .svc-page-navigator-item-content {
  opacity: 0;
}

.svc-page-navigator-item__dot {
  box-sizing: content-box;
  position: absolute;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  right: calc(50% - 3px);
  top: calc(50% - 3px);
  background: var(--sjs-border-default, var(--border, #d6d6d6));
}

.svc-page-navigator-item--selected .svc-page-navigator-item__dot {
  width: var(--sjs-base-unit, var(--base-unit, 8px));
  height: var(--sjs-base-unit, var(--base-unit, 8px));
  right: calc(50% - 1.5 * var(--sjs-base-unit, var(--base-unit, 8px)) / 2);
  top: calc(50% - 1.5 * var(--sjs-base-unit, var(--base-unit, 8px)) / 2);
  background: var(--sjs-general-backcolor, var(--background, #fff));
  border: calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) solid var(--sjs-primary-backcolor, var(--primary, #19b394));
}

.svc-page-navigator-item__banner {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  right: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  display: flex;
  align-items: center;
  line-height: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  animation: 0.5s ease-in;
  padding: 0;
  opacity: 0;
  z-index: 20;
}
.svc-page-navigator-item__banner .svc-page-navigator-item__dot {
  position: absolute;
  display: inline-block;
  top: calc(1.75 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  right: calc(1.75 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-creator__toolbox--right .svc-page-navigator-item__banner,
[dir=rtl] .svc-page-navigator-item__banner,
[style*="direction:rtl"] .svc-page-navigator-item__banner,
[style*="direction: rtl"] .svc-page-navigator-item__banner {
  right: unset;
  left: calc(0.625 * var(--base-unit, 8px));
}
.svc-creator__toolbox--right .svc-page-navigator-item__banner .svc-page-navigator-item__dot,
[dir=rtl] .svc-page-navigator-item__banner .svc-page-navigator-item__dot,
[style*="direction:rtl"] .svc-page-navigator-item__banner .svc-page-navigator-item__dot,
[style*="direction: rtl"] .svc-page-navigator-item__banner .svc-page-navigator-item__dot {
  right: unset;
  left: calc(1.75 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-page-navigator-item-content:not(.svc-page-navigator-item--disabled) .svc-page-navigator-item__banner .svc-page-navigator-item__dot {
  width: var(--sjs-base-unit, var(--base-unit, 8px));
  height: var(--sjs-base-unit, var(--base-unit, 8px));
  border: none;
  background: var(--sjs-primary-backcolor, var(--primary, #19b394));
}

.svc-page-navigator-item-content:not(.svc-page-navigator-item--selected):hover .svc-page-navigator-item__banner,
.svc-page-navigator-item-content:not(.svc-page-navigator-item--selected):focus .svc-page-navigator-item__banner {
  padding: 0 calc(4.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) 0 calc(2.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  max-width: calc(25 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  opacity: 1;
}

.svc-creator__toolbox--right .svc-page-navigator-item-content:not(.svc-page-navigator-item--selected):hover .svc-page-navigator-item__banner,
.svc-creator__toolbox--right .svc-page-navigator-item-content:not(.svc-page-navigator-item--selected):focus .svc-page-navigator-item__banner,
[dir=rtl] .svc-page-navigator-item-content:not(.svc-page-navigator-item--selected):hover .svc-page-navigator-item__banner,
[dir=rtl] .svc-page-navigator-item-content:not(.svc-page-navigator-item--selected):focus .svc-page-navigator-item__banner,
[style*="direction:rtl"] .svc-page-navigator-item-content:not(.svc-page-navigator-item--selected):hover .svc-page-navigator-item__banner,
[style*="direction:rtl"] .svc-page-navigator-item-content:not(.svc-page-navigator-item--selected):focus .svc-page-navigator-item__banner,
[style*="direction: rtl"] .svc-page-navigator-item-content:not(.svc-page-navigator-item--selected):hover .svc-page-navigator-item__banner,
[style*="direction: rtl"] .svc-page-navigator-item-content:not(.svc-page-navigator-item--selected):focus .svc-page-navigator-item__banner {
  padding: 0 calc(2.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) 0 calc(4.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-page-navigator-item--disabled .svc-page-navigator-item__banner {
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  opacity: 0.25;
}