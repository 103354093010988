svc-page {
  display: block;
}

.svc-page {
  margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.svc-page .sd-page__title {
  margin: calc(2.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) 0 0 0;
}

.svc-page__content {
  position: relative;
  border-radius: var(--sjs-base-unit, var(--base-unit, 8px));
  padding: var(--sjs-base-unit, var(--base-unit, 8px)) calc(0 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  box-sizing: border-box;
  width: 100%;
  outline: none;
}
.svc-page__content .sd-page {
  margin: 0;
  padding: var(--sjs-base-unit, var(--base-unit, 8px)) calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-page__content-actions {
  position: absolute;
  display: none;
  top: var(--sjs-base-unit, var(--base-unit, 8px));
  inset-inline-end: 0;
  padding: 0 calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  z-index: 1;
}

.svc-page__content:not(.svc-page__content--new):focus,
.svc-hovered.svc-page__content:not(.svc-page__content--new) {
  background: var(--sjs-secondary-backcolor-semi-light, rgba(255, 152, 20, 0.1));
}

.svc-creator .svc-page .svc-page__content--selected.svc-page__content--selected,
.svc-creator .svc-page .svc-page__content--selected:focus.svc-page__content--selected,
.svc-hovered.svc-page__content--selected {
  box-shadow: 0 0 0 2px inset var(--sjs-secondary-backcolor, var(--secondary, #ff9814));
  background: var(--sjs-secondary-backcolor-semi-light, rgba(255, 152, 20, 0.1));
}
.svc-creator .svc-page .svc-page__content--selected.svc-page__content--selected .svc-page__content-actions,
.svc-creator .svc-page .svc-page__content--selected:focus.svc-page__content--selected .svc-page__content-actions,
.svc-hovered.svc-page__content--selected .svc-page__content-actions {
  display: block;
}

.svc-page__footer {
  overflow: visible;
  margin-left: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin-right: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  gap: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.svc-page__footer .sv-action {
  flex: 1 1 0;
}
.svc-page__footer .svc-btn {
  flex-grow: 1;
  border: none;
}

.svc-page__add-new-question .svc-text {
  margin-left: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-page__add-new-question {
  -webkit-tap-highlight-color: transparent;
}

.svc-page__question-type-selector {
  appearance: none;
  display: flex;
  height: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding: var(--sjs-base-unit, var(--base-unit, 8px));
  box-sizing: border-box;
  border: none;
  border-radius: 2px;
  background-color: transparent;
  cursor: pointer;
  margin-inline-end: var(--sjs-base-unit, var(--base-unit, 8px));
  outline: none;
}
.svc-page__question-type-selector use {
  fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}
.svc-page__question-type-selector:hover {
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
}
.svc-page__question-type-selector:focus {
  background-color: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
}

.svc-page--drag-over-empty:after {
  content: " ";
  position: absolute;
  background: var(--sjs-secondary-backcolor, var(--secondary, #ff9814));
  left: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  bottom: calc(11.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  width: calc(100% - 48px);
  height: 2px;
}

.svc-page--drag-over-empty-no-add-button:after {
  bottom: auto;
}

.svc-page__placeholder_frame {
  min-width: auto;
  max-width: 100%;
  padding: 0 calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.svc-page__placeholder_frame .svc-panel__placeholder_frame {
  padding: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: auto;
}