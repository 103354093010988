.svc-image-question-controls {
  position: absolute;
  top: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  right: var(--sjs-base-unit, var(--base-unit, 8px));
  display: none;
}

.svc-question__content--selected .svc-image-question-controls {
  display: flex;
}

.svc-question__content--image:not(.svc-question__content--loading):not(.svc-question__content--empty) {
  padding: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px)))) 0 calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px)))) 0;
}
.svc-question__content--image:not(.svc-question__content--loading):not(.svc-question__content--empty) .sd-image__image {
  border-radius: 0;
}

.svc-question__content--image.svc-question__content--empty .sd-file,
.svc-question__content--image.svc-question__content--empty sv-ng-file-question {
  width: 100%;
}

.svc-question__content--image.svc-question__content--empty .sd-question--image {
  display: none;
}

.svc-question__content--image .sd-file__choose-btn--text .sv-svg-icon {
  display: none;
}

@container (max-width: 176px) {
  .svc-question__content--image .sd-file__choose-btn--text .sv-svg-icon {
    display: block;
  }
}
.svc-question__content--loading .sd-image {
  display: none;
}

.svc-image-question__loading-placeholder {
  position: relative;
  background-color: var(--sjs-general-backcolor, var(--background, #fff));
  width: 100%;
  height: calc(27.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  border: 1px dashed var(--sjs-border-inside, var(--border-inside, rgba(0, 0, 0, 0.16)));
}

.svc-image-question__loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}