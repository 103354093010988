svc-tab-designer {
  width: 100%;
  height: 100%;
  background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
}

.svc-tab-designer {
  display: flex;
  flex-grow: 1;
  height: 100%;
  overflow-y: scroll;
  position: static;
}

.svc-tab-designer--with-place-holder .svc-tab-designer_content {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.svc-tab-designer--with-place-holder .svc-designer-header {
  min-width: calc(84 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-tab-designer .svc-designer-header .sd-container-modern__title {
  background-color: transparent;
}
.svc-tab-designer .svc-tab-designer_content {
  width: 100%;
}
.svc-tab-designer .sd-title {
  display: flex;
}
.svc-tab-designer .sd-container-modern {
  min-width: calc(70 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  width: 100%;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}
.svc-tab-designer .sd-container-modern.sd-container-modern--static {
  max-width: calc(84 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.svc-tab-designer .sd-container-modern.sd-container-modern--responsive {
  max-width: initial;
}
.svc-tab-designer .sd-question.sd-question--image {
  width: 100%;
}
.svc-tab-designer .sd-progress,
.svc-tab-designer .sd-progress__bar {
  background-color: transparent;
}

.svc-designer-header {
  border-bottom: 2px solid transparent;
}

.svc-designer__placeholder-container {
  display: flex;
  flex-grow: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  box-sizing: border-box;
  text-align: center;
}

.svc-designer-placeholder-page {
  margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-designer-placeholder-page .svc-page__footer {
  width: calc(33 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin: auto;
}

.svc-designer-placeholder-page .svc-row--ghost {
  display: none;
}