svc-tab-test {
  width: 100%;
  height: 100%;
  background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
}

.svc-test-tab__content .svc-plugin-tab__content {
  overflow-y: overlay;
}
.svc-test-tab__content .svc-plugin-tab__content .sv-root-modern .sv-completedpage,
.svc-test-tab__content .svc-plugin-tab__content .sv_default_css .sv_completed_page {
  margin: 0;
  border: 0;
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
}
.svc-test-tab__content .svc-plugin-tab__content .sv_default_css .sv_body {
  border: 0;
}
.svc-test-tab__content .svc-plugin-tab__content .svc-preview__test-again {
  width: calc(33 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin-left: auto;
  margin-right: auto;
  margin-bottom: calc(12 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin-top: 8px;
}

.svc-creator-tab__content--with-toolbar.svc-test-tab__content .svc-plugin-tab__content {
  height: calc(100% - 6 * var(--sjs-base-unit, var(--base-unit, 8px)));
}

.svc-test-tab__content-actions {
  position: relative;
}
.svc-test-tab__content-actions .sv-action-bar {
  padding: 0;
  height: calc(6 * var(--sjs-base-unit, var(--base-unit, 8px)));
  background: var(--sjs-general-backcolor, var(--background, #fff));
  border-top: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
  width: 100%;
  position: absolute;
}
.svc-test-tab__content-actions .sv-action-bar.sv-action-bar--pages {
  left: 0;
  right: 0;
  justify-content: center;
  gap: var(--sjs-base-unit, var(--base-unit, 8px));
}
.svc-test-tab__content-actions .sv-action-bar.sv-action-bar--pages .sv-action__content {
  padding: 0;
}
.svc-test-tab__content-actions .sv-action-bar.sv-action-bar--pages .sv-action-bar-item {
  margin: 0;
}
.svc-test-tab__content-actions .sv-action-bar-item {
  --small-bold-font-size: var(--sjs-font-size, calc(2 * var(--sjs-base-unit, var(--base-unit, 8px))));
  font-family: var(--sjs-font-family, var(--font-family));
  font-style: normal;
  font-weight: 600;
  font-size: calc(0.75 * var(--small-bold-font-size));
  line-height: var(--sjs-font-size, calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))));
  width: 100%;
  height: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.svc-test-tab__content-actions .svc-page-selector {
  max-width: 50%;
}
.svc-test-tab__content-actions .svc-page-selector .sv-action-bar-item__title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  display: inline-block;
}

.svc-test-tab__content .sd-body--empty {
  font-family: var(--sjs-font-family, var(--font-family));
  font-style: normal;
  font-weight: 400;
  font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  text-align: center;
  overflow: hidden;
  position: static;
  display: flex;
  flex-direction: column;
}