.svc-switcher.spg-action-button {
  align-items: center;
  gap: var(--sjs-base-unit, var(--base-unit, 8px));
  pointer-events: auto;
}

.svc-switcher__title {
  color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  font-family: var(--sjs-font-family, var(--font-family));
  font-size: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  font-style: normal;
  font-weight: 600;
  line-height: var(--sjs-base-unit, var(--base-unit, 8px));
}

.svc-switcher__icon {
  width: calc(2.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
  background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  transition-duration: 0.2s;
  transition-property: background;
  transition-timing-function: linear;
}

.svc-switcher__icon:hover {
  background: var(--sjs-general-backcolor, var(--background, #fff));
}

.svc-switcher__icon:focus {
  border: calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) solid var(--sjs-primary-backcolor, var(--primary, #19b394));
  background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
}

.svc-switcher__icon:hover:focus {
  border: calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) solid var(--sjs-primary-backcolor, var(--primary, #19b394));
  background: var(--sjs-general-backcolor, var(--background, #fff));
}

.svc-switcher__icon--checked,
.svc-switcher__icon--checked:hover {
  justify-content: center;
  align-items: center;
  border: unset;
  border-radius: 100px;
  background-color: var(--sjs-primary-backcolor, var(--primary, #19b394));
}

.svc-switcher__icon--checked:focus {
  border: calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) solid var(--sjs-primary-backcolor, var(--primary, #19b394));
  background-color: var(--sjs-primary-forecolor, var(--primary-foreground, #fff));
}

.svc-switcher__icon-thumb {
  width: var(--sjs-base-unit, var(--base-unit, 8px));
  height: var(--sjs-base-unit, var(--base-unit, 8px));
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: var(--sjs-general-dim-forecolor-light, rgba(0, 0, 0, 0.45));
  transform: translateX(0);
  margin-left: 0%;
  transition-duration: 0.2s;
  transition-property: margin-left, transform, background-color;
  transition-timing-function: linear;
}

.svc-switcher__icon--checked .svc-switcher__icon-thumb {
  background-color: var(--sjs-primary-forecolor, var(--primary-foreground, #fff));
  margin-left: 100%;
  transform: translateX(-100%);
}

.svc-switcher__icon:focus .svc-switcher__icon-thumb,
.svc-switcher__icon:hover:focus .svc-switcher__icon-thumb,
.svc-switcher__icon--checked:focus .svc-switcher__icon-thumb {
  background-color: var(--sjs-primary-backcolor, var(--primary, #19b394));
}