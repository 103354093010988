/* .Toastify__toast-body {
  padding: 0 !important;
    border: 1px solid blue;

}

.Toastify__toast-content {
  padding: 0 !important;
    border: 1px solid yellow;

}


.Toastify__toast-body {
  border: 1px solid red;
  padding: 0 !important;
} */

.toastify__toast-container,
.Toastify__toast,
.Toastify__toast-body {
  padding: 0px !important;
}

.Toastify * {
  /* padding-top: 0 !important;
  padding-bottom: 0 !important; */

  /* margin: 0 !important; */
}

.tox-notification,
.tox-notification--in,
.tox-notification--warning {
  display: none !important;
}