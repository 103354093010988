.svc-row {
  width: 100%;
  position: relative;
}

.svc-row:not(.svc-row--ghost) > .sd-row {
  min-height: 50px;
}

.svc-row > .sd-row.sd-page__row {
  margin-top: calc(2 * var(--sjs-base-unit, var(--base-unit, 8px)));
}

.svc-row .sd-row.sd-row--multiple {
  overflow: auto;
  width: calc(100% + 4 * var(--sjs-base-unit, var(--base-unit, 8px)));
  flex-wrap: nowrap;
  margin-top: 0;
  padding-top: calc(2 * var(--sjs-base-unit, var(--base-unit, 8px)));
  padding-right: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-bottom: 2px;
  margin-right: calc(-2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin-bottom: -2px;
}
.svc-row .sd-row.sd-row--multiple .sd-row--multiple {
  overflow: unset;
}

.sd-panel .svc-row .sd-row--multiple {
  padding: calc(0 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  box-shadow: none;
  border-radius: 0;
  padding: 2px;
  margin: -2px;
  width: 100%;
}
.sd-panel .svc-row .sd-row--multiple > div {
  padding: 0;
}

.svc-row.svc-row--ghost .svc-question__content {
  padding: 0;
  border: none;
  box-shadow: none;
}
.svc-row.svc-row--ghost .sd-row {
  height: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.svc-row.svc-row--ghost:last-child .sd-row {
  height: initial;
  margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin-bottom: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.svc-row.svc-row--ghost + .svc-row .sd-page__row {
  margin-top: 0;
}

.svc-panel .svc-row.svc-row--ghost.svc-row.svc-row--ghost .sd-row {
  height: initial;
  margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin-bottom: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-row--drag-over-top:before {
  content: " ";
  position: absolute;
  left: 0;
  background: var(--sjs-secondary-backcolor, var(--secondary, #ff9814));
  top: 6px;
  width: 100%;
  height: 2px;
}

.svc-row--drag-over-bottom:after {
  content: " ";
  position: absolute;
  left: 0;
  background: var(--sjs-secondary-backcolor, var(--secondary, #ff9814));
  bottom: -10px;
  width: 100%;
  height: 2px;
}

.svc-question__content--panel .svc-row--drag-over-top:before,
.sd-panel__content .svc-row--drag-over-top:before {
  top: -4px;
}
.svc-question__content--panel .svc-row--drag-over-bottom:after,
.sd-panel__content .svc-row--drag-over-bottom:after {
  bottom: -4px;
}
.svc-row .sd-row--fade-out,
.svc-row .sd-row--fade-in {
  animation: none;
}
.svc-row .sd-element-wrapper--fade-out,
.svc-row .sd-element-wrapper--fade-in {
  animation: none;
}
.svc-row .sd-element__content--fade-in,
.svc-row .sd-element__content--fade-out {
  animation: none;
}
.svc-row .sd-paneldynamic__panel-wrapper--fade-in,
.svc-row .sd-paneldynamic__panel-wrapper--fade-out,
.svc-row .sd-paneldynamic__panel-wrapper--fade-out-left,
.svc-row .sd-paneldynamic__panel-wrapper--fade-out-right,
.svc-row .sd-paneldynamic__panel-wrapper--fade-in-left,
.svc-row .sd-paneldynamic__panel-wrapper--fade-in-right {
  animation: none;
}
.svc-row .sd-table__row--fade-out,
.svc-row .sd-table__row--fade-in {
  animation: none;
}