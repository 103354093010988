.spg-action-bar {
  display: flex;
  box-sizing: content-box;
  padding-right: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  position: relative;
  align-items: center;
  margin-left: auto;
  overflow: hidden;
  white-space: nowrap;
}
.spg-action-bar .sv-action:not(.sv-action--hidden):not(:last-of-type) > .sv-action__content {
  padding-right: 0;
}

.spg-action-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  padding: var(--sjs-base-unit, var(--base-unit, 8px));
  box-sizing: border-box;
  border: none;
  color: var(--sjs-primary-backcolor, var(--primary, #19b394));
  font-weight: 600;
  font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  font-family: var(--sjs-font-family, var(--font-family));
  border-radius: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  background-color: transparent;
  cursor: pointer;
}
.spg-action-button:hover, .spg-action-button:focus {
  opacity: 1;
  outline: none;
  background-color: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
}
.spg-action-button:hover use, .spg-action-button:focus use {
  fill: var(--sjs-primary-backcolor, var(--primary, #19b394));
}

.spg-action-button__icon {
  display: block;
}
.spg-action-button__icon use {
  fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}

.spg-action-button--danger {
  color: var(--sjs-special-red, var(--red, #e60a3e));
}
.spg-action-button--danger:hover, .spg-action-button--danger:focus {
  background-color: var(--sjs-special-red-light, var(--red-light, rgba(230, 10, 62, 0.1)));
}
.spg-action-button--danger:hover use, .spg-action-button--danger:focus use {
  fill: var(--sjs-special-red, var(--red, #e60a3e));
}

button.spg-action-button--large {
  padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.spg-action-button:disabled {
  opacity: 0.25;
  pointer-events: none;
  cursor: default;
}

.spg-action-button--muted {
  opacity: 0.5;
}

.spg-action-button:active {
  opacity: 0.5;
}

.spg-action-button--text {
  padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.spg-question {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
}

.spg-question__header {
  width: 100%;
}

.spg-question__title {
  width: 100%;
  margin: 0;
  padding: var(--sjs-base-unit, var(--base-unit, 8px)) 0;
  line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  font-family: var(--sjs-font-family, var(--font-family));
  color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  font-weight: normal;
  box-sizing: border-box;
}

.spg-question__content {
  width: 100%;
  color: var(--sjs-primary-backcolor, var(--primary, #19b394));
  cursor: pointer;
}

.spg-text__content,
.spg-comment__content {
  position: relative;
}

.spg-question__content:focus-within .spg-remaining-character-counter {
  display: flex;
}

.spg-remaining-character-counter {
  display: none;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0px;
  font-family: var(--sjs-font-family, var(--font-family));
  line-height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  font-size: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  position: absolute;
  inset-inline-end: calc(0.75 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  inset-block-end: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.spg-question__description {
  line-height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  font-size: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  color: var(--sjs-general-dim-forecolor-light, rgba(0, 0, 0, 0.45));
  padding-bottom: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  white-space: normal;
}

.spg-row-narrow__question {
  margin-top: calc(-2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.spg-row--multiple > div > .spg-row-narrow__question {
  margin-top: calc(-1 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.spg-question--location--left {
  flex-direction: row;
  background: var(--sjs-general-backcolor, var(--background, #fff));
  box-shadow: inset 0 0 0 1px var(--sjs-border-inside, var(--border-inside, rgba(0, 0, 0, 0.16)));
  line-height: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  vertical-align: middle;
  align-items: stretch;
}
.spg-question--location--left:focus-within {
  box-shadow: inset 0 0 0 2px var(--sjs-primary-backcolor, var(--primary, #19b394));
}

.spg-question__header--location--left {
  width: auto;
  box-sizing: border-box;
  max-width: 50%;
  flex: 1;
  display: flex;
  align-items: center;
}
.spg-question__header--location--left .spg-question__title {
  padding: var(--sjs-base-unit, var(--base-unit, 8px)) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  border-right: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
  display: inline-block;
}

.spg-question__content--left {
  flex: 2;
}
.spg-question__content--left .spg-input.spg-input.spg-input {
  background-color: transparent;
  box-shadow: none;
  border: none;
}
.spg-question__content--left .spg-input.spg-input.spg-input:focus, .spg-question__content--left .spg-input.spg-input.spg-input:focus-within {
  box-shadow: none;
}

.spg-row--multiple {
  display: flex;
  gap: var(--sjs-base-unit, var(--base-unit, 8px));
  flex-wrap: wrap;
}
.spg-row--multiple > div {
  flex-grow: 1;
  min-width: calc(25.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.spg-row--multiple > div .spg-question__header--location--left {
  min-width: calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.spg-row--multiple > div input {
  min-width: max(17 * var(--sjs-base-unit, var(--base-unit, 8px)), 100%);
  width: 0;
}

.spg-question__erbox {
  padding: var(--sjs-base-unit, var(--base-unit, 8px)) calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) var(--sjs-base-unit, var(--base-unit, 8px)) calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  background-color: var(--sjs-special-red-light, var(--red-light, rgba(230, 10, 62, 0.1)));
  border-radius: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.spg-question__erbox > div,
sv-question-error > div {
  display: flex;
  gap: var(--sjs-base-unit, var(--base-unit, 8px));
}

.spg-question__erbox-icon {
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  width: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  background-size: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  background-repeat: no-repeat;
  background-position: 0 0;
  line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  white-space: normal;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='20' viewBox='0 0 24 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.0336 15.4803L14.5736 1.45346C13.9936 0.487805 12.9936 0 12.0036 0C11.0136 0 10.0136 0.487805 9.43364 1.45346L0.973642 15.4803C-0.226358 17.4714 1.21364 20 3.54364 20H20.4536C22.7836 20 24.2236 17.4714 23.0236 15.4803H23.0336ZM21.3336 17.5112C21.2436 17.6605 20.9936 18.0189 20.4636 18.0189H3.54364C3.01364 18.0189 2.76364 17.6705 2.67364 17.5112C2.58364 17.3519 2.41364 16.9537 2.68364 16.5057L11.1436 2.47885C11.4036 2.04082 11.8336 1.99104 12.0036 1.99104C12.1736 1.99104 12.5936 2.04082 12.8636 2.47885L21.3136 16.5057C21.5836 16.9537 21.4136 17.3519 21.3236 17.5112H21.3336ZM13.0036 7.05824V12.0358C13.0036 12.5834 12.5536 13.0314 12.0036 13.0314C11.4536 13.0314 11.0036 12.5834 11.0036 12.0358V7.05824C11.0036 6.5107 11.4536 6.06272 12.0036 6.06272C12.5536 6.06272 13.0036 6.5107 13.0036 7.05824ZM13.0036 15.0224C13.0036 15.5699 12.5536 16.0179 12.0036 16.0179C11.4536 16.0179 11.0036 15.5699 11.0036 15.0224C11.0036 14.4749 11.4536 14.0269 12.0036 14.0269C12.5536 14.0269 13.0036 14.4749 13.0036 15.0224Z' fill='%23E60A3E'/%3E%3C/svg%3E");
}

.spg-question__erbox--location--bottom {
  margin-top: var(--sjs-base-unit, var(--base-unit, 8px));
}

.spg-restfull .spg-panel__content,
.spg-masksettings .spg-panel__content {
  gap: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.spg-restfull .spg-panel__content .spg-row,
.spg-masksettings .spg-panel__content .spg-row {
  margin-top: 0;
}

.spg-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  background-color: var(--sjs-general-backcolor, var(--background, #fff));
  box-sizing: border-box;
  width: 100%;
  height: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  border: none;
  box-shadow: inset 0 0 0 1px var(--sjs-border-inside, var(--border-inside, rgba(0, 0, 0, 0.16)));
  padding: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  outline: none;
  font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  font-family: var(--sjs-font-family, var(--font-family));
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
}

.spg-input::placeholder {
  color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}

.spg-input:focus,
.spg-input.spg-dropdown:focus,
.spg-input.spg-dropdown:focus-within,
.spg-input-container:focus-within {
  box-shadow: inset 0 0 0 2px var(--sjs-primary-backcolor, var(--primary, #19b394));
}

.spg-input:disabled,
.spg-input:disabled::placeholder {
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  opacity: 0.25;
}

input.spg-input:read-only,
input.spg-input:read-only::placeholder {
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  opacity: 0.25;
}

.spg-input[type=color] {
  padding-left: 0;
  padding-right: 0;
  min-width: calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.spg-input__edit-button {
  position: relative;
  box-sizing: border-box;
  appearance: none;
  background: var(--sjs-general-backcolor, var(--background, #fff));
  border: none;
  outline: none;
  width: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding: var(--sjs-base-unit, var(--base-unit, 8px));
  line-height: 0;
}
.spg-input__edit-button:focus, .spg-input__edit-button:hover {
  background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
}
.spg-input__edit-button:active {
  opacity: 0.5;
}
.spg-input__edit-button.spg-input__edit-button--disabled, .spg-input__edit-button:disabled {
  opacity: 0.25;
  background: var(--sjs-general-backcolor, var(--background, #fff));
}
.spg-input__edit-button svg {
  height: 24px;
  width: 24px;
  fill: rgba(0, 0, 0, 0.45);
}

.spg-input.spg-input--error {
  box-shadow: 0 0 0 1px inset var(--sjs-special-red, var(--red, #e60a3e));
}

.spg-input-container {
  display: flex;
  justify-content: space-between;
  cursor: default;
  padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  align-items: center;
  gap: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  box-shadow: 0 0 0 1px inset var(--sjs-border-inside, var(--border-inside, rgba(0, 0, 0, 0.16)));
}

.spg-input-container--multiline {
  align-items: end;
  flex-direction: column;
  gap: 0;
  height: auto;
}
.spg-input-container--multiline sv-ng-question-comment {
  height: calc(9 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.spg-input-container--multiline .spg-input-container__input {
  box-sizing: border-box;
  resize: none;
}

.spg-input-container__input {
  flex-grow: 1;
  width: 100%;
  padding: var(--sjs-base-unit, var(--base-unit, 8px)) calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  font-family: var(--sjs-font-family, var(--font-family));
  outline: none;
  border: none;
  line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  background-color: transparent;
}

.spg-input-container__input:disabled {
  opacity: 0.25;
}

.spg-input-container__buttons-container {
  display: flex;
  gap: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.spg-dropdown {
  border-radius: 0;
  display: flex;
  padding-right: calc(5.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  background-position: right calc(1.625 * (var(--sjs-base-unit, var(--base-unit, 8px)))) top 50%, 0 0;
}

.spg-dropdown__filter-string-input {
  line-height: unset;
}

.spg-dropdown_chevron-button {
  line-height: normal;
}

.spg-dropdown-popup:not(.sv-popup--dropdown-overlay) {
  height: 0;
}

.spg-comment {
  height: calc(9 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  max-width: 100%;
  min-width: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  min-height: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.spg-panel__title {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  width: 100%;
  padding: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  border: none;
  outline: none;
  color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  cursor: pointer;
  text-align: left;
  background-color: var(--sjs-general-backcolor, var(--background, #fff));
  box-shadow: inset 0px -1px 0px var(--sjs-border-default, var(--border, #d6d6d6));
  font-family: var(--sjs-font-family, var(--font-family));
  font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  font-weight: 400;
  margin: 0;
}

.spg-panel__title:disabled,
.spg-panel__title:disabled:hover {
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  opacity: 0.25;
  cursor: default;
}

.spg-panel__title--collapsed:hover,
.spg-panel__title--expandable:focus {
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
}

.spg-panel__title--expanded {
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  font-weight: 600;
}

.spg-panel__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0 calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  box-sizing: border-box;
  background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  box-shadow: inset 0px -1px 0px var(--sjs-border-default, var(--border, #d6d6d6));
}

.spg-panel__content .spg-row {
  width: 100%;
  margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.spg-panel__content .spg-row--multiple {
  margin-top: var(--sjs-base-unit, var(--base-unit, 8px));
}

.sv-mobile-side-bar .spg-panel__content {
  padding: 0 calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.spg-question__nopadding .spg-panel__content {
  padding: 0px;
  box-shadow: unset;
}

.spg-checkbox {
  cursor: pointer;
  margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.spg-checkbox--disabled {
  cursor: default;
}
.spg-checkbox--disabled .spg-checkbox {
  cursor: default;
}
.spg-checkbox--disabled .spg-checkbox__caption {
  color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}

.spg-checkbox__caption {
  font-family: var(--sjs-font-family, var(--font-family));
  font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.spg-checkbox__rectangle {
  background: var(--sjs-general-backcolor, var(--background, #fff));
  border: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
  box-sizing: border-box;
  width: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  flex-shrink: 0;
  padding: 1px 1px;
}

.spg-checkbox:hover .spg-checkbox__rectangle {
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
}

.spg-checkbox--disabled .spg-checkbox__rectangle:hover {
  background: var(--sjs-general-backcolor, var(--background, #fff));
}

.spg-checkbox__control:focus + .spg-checkbox__rectangle {
  outline: 2px solid var(--sjs-primary-backcolor, var(--primary, #19b394));
  outline-offset: -2px;
}

.spg-checkbox .spg-checkbox__svg {
  display: none;
}

.spg-checkbox--checked .spg-checkbox__svg {
  display: block;
  width: calc(2.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(2.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  background-repeat: no-repeat;
  background-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  fill: var(--sjs-primary-backcolor, var(--primary, #19b394));
}

.spg-checkbox--disabled.spg-checkbox--checked .spg-checkbox__svg {
  fill: var(--sjs-border-default, var(--border, #d6d6d6));
}

.spg-checkbox__control {
  position: fixed;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
}

.spg-checkbox__hidden {
  display: none;
}

.spg-selectbase {
  border: none;
  padding: 0;
  margin: 0;
}

.spg-selectbase__label {
  display: flex;
  align-items: flex-start;
  gap: var(--sjs-base-unit, var(--base-unit, 8px));
}

.spg-checkbox .spg-question__description {
  padding: var(--sjs-base-unit, var(--base-unit, 8px)) 0 0 calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.spg-checkbox .spg-help-action use {
  pointer-events: none;
}

.spg-matrixdynamic__drag-element {
  padding: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.spg-matrixdynamic__drag-element:hover {
  background-color: var(--sjs-general-backcolor, var(--background, #fff));
}
.spg-matrixdynamic__drag-element:after {
  content: " ";
  display: block;
  height: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.spg-drag-element__svg {
  width: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding: var(--sjs-base-unit, var(--base-unit, 8px));
  display: block;
  opacity: 0.5;
}
.spg-drag-element__svg use {
  fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}

.spg-matrixdynamic__drag-drop-ghost-position-top,
.spg-matrixdynamic__drag-drop-ghost-position-bottom {
  position: relative;
}

.spg-matrixdynamic__drag-drop-ghost-position-top::after,
.spg-matrixdynamic__drag-drop-ghost-position-bottom::after {
  content: "";
  width: 100%;
  height: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  background-color: var(--sjs-secondary-backcolor, var(--secondary, #ff9814));
  position: absolute;
  left: 0;
}

.spg-matrixdynamic__drag-drop-ghost-position-top::after {
  top: 0;
}

.spg-matrixdynamic__drag-drop-ghost-position-bottom::after {
  bottom: 0;
}

.spg-matrixdynamic__placeholder {
  padding: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px)))) 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--sjs-general-backcolor, var(--background, #fff));
  border: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
  box-sizing: border-box;
}
.spg-matrixdynamic__placeholder .spg-matrixdynamic__add-btn {
  display: initial;
}

.spg-matrixdynamic__placeholder-text {
  font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}

.spg-matrixdynamic__add-btn {
  display: none;
  appearance: none;
  border: none;
  margin-top: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  background: transparent;
  color: var(--sjs-primary-backcolor, var(--primary, #19b394));
  font-family: var(--sjs-font-family, var(--font-family));
  font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  font-weight: 600;
  line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.spg-smiley-icon {
  width: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin: var(--sjs-base-unit, var(--base-unit, 8px));
  border-radius: 100px;
  border: 1px solid var(--sjs-general-forecolor, var(--foreground, #161616));
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.spg-smiley-icon svg {
  width: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  fill: var(--sjs-general-forecolor, var(--foreground, #161616));
}

.spg-table-wrapper {
  border: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
  border-bottom: none;
}

.spg-table {
  width: 100%;
  background-color: var(--sjs-general-backcolor, var(--background, #fff));
  border-collapse: collapse;
}
.spg-table tr {
  border-bottom: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
}

.spg-table__cell {
  padding: 0;
}
.spg-table__cell .spg-checkbox {
  margin: 0 calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.spg-table__cell--detail-panel {
  background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
}
.spg-table__cell--detail-panel .spg-panel__content {
  box-shadow: none;
}

.spg-table__cell:not(.spg-table__cell--detail-panel):not(.spg-table__cell--actions):first-of-type {
  padding-left: 8px;
}

.spg-table__cell:not(.spg-table__cell--detail-panel) .spg-input {
  appearance: none;
  line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  box-shadow: none;
  border-radius: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  background-color: transparent;
  padding: var(--sjs-base-unit, var(--base-unit, 8px));
  height: auto;
}
.spg-table__cell:not(.spg-table__cell--detail-panel) .spg-input[type=color] {
  padding-right: 0;
}
.spg-table__cell:not(.spg-table__cell--detail-panel) .spg-input:focus, .spg-table__cell:not(.spg-table__cell--detail-panel) .spg-input:focus-within {
  box-shadow: inset 0 0 0 2px var(--sjs-primary-backcolor, var(--primary, #19b394));
}

.spg-table__cell:not(.spg-table__cell--detail-panel) .spg-dropdown_chevron-button {
  display: none;
}

.spg-table__cell:not(.spg-table__cell--detail-panel) .spg-dropdown {
  width: max-content;
  padding-right: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  background-size: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  background-position: right calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) top 50%, 0 0;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 15L17 10H7L12 15Z' fill='%2390909080'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
}

.spg-table__cell:not(.spg-table__cell--detail-panel):first-of-type .spg-text {
  padding-left: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.spg-table__cell--actions:first-of-type {
  width: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.spg-table__cell--actions:last-of-type .spg-action-bar {
  justify-content: flex-end;
}

.spg-table__cell--actions > .spg-matrixdynamic__drag-element {
  display: inline-block;
  margin-top: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  cursor: move;
}

.spg-table__cell--header {
  font-size: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  font-weight: normal;
  color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100px;
}
.spg-table__cell--header span {
  display: none;
}
.spg-table__cell--header .sv-string-viewer {
  display: inline;
  white-space: unset;
}

.spg-table__question-wrapper {
  padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) 0;
}

.spg-question-group .spg-row {
  margin-top: 0;
}
.spg-question-group .spg-row:not(:first-of-type) {
  margin-top: var(--sjs-base-unit, var(--base-unit, 8px));
}

.spg-color-editor__color-input {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  left: 0;
  top: 100%;
  transform: translateY(-50%);
}

.spg-color-editor__color-item {
  display: flex;
  gap: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.spg-color-editor__color-item .spg-color-editor__color-swatch {
  margin-left: 0;
}

.spg-color-editor__color-swatch {
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 3px;
  width: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.16);
  margin-left: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.spg-color-editor__color-swatch .sv-svg-icon {
  position: absolute;
  width: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  fill: var(--sjs-general-backcolor, var(--background, #fff));
}

.spg-color-editor__color-swatch--disabled .sv-svg-icon {
  opacity: 0.25;
}

.spg-color-editor__color-swatch--default .sv-svg-icon {
  opacity: 1;
}

.spg-color-editor .spg-input__edit-button + * {
  margin-inline-end: calc(-0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.spg-file-edit__choose-button {
  position: relative;
}

.spg-link {
  display: flex;
}

.sv-string-viewer .spg-link {
  display: initial;
  color: var(--sjs-primary-backcolor, var(--primary, #19b394));
  font-weight: 600;
  text-decoration-line: underline;
}

.spg-theme-builder-root .sv-skeleton-element {
  min-height: 50px;
}
.spg-theme-builder-root .spg-paneldynamic__separator {
  border: none;
  margin: 0;
  height: var(--sjs-base-unit, var(--base-unit, 8px));
}
.spg-theme-builder-root .spg-panel__footer {
  justify-content: center;
  margin: var(--sjs-base-unit, var(--base-unit, 8px)) 0 0 0;
  padding: 0;
}
.spg-theme-builder-root .spg-paneldynamic__add-btn {
  margin: 0 auto;
}
.spg-theme-builder-root .spg-row--multiple .spg-spin-editor .spg-input-container__buttons-container {
  display: none;
}
.spg-theme-builder-root .spg-row--multiple .spg-question__header--location--left {
  min-width: auto;
}
.spg-theme-builder-root .spg-row--multiple > div {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(50% - var(--sjs-base-unit, var(--base-unit, 8px)) * 0.5);
  min-width: auto;
}
.spg-theme-builder-root .spg-row--multiple > div input {
  min-width: 0;
}
.spg-theme-builder-root .spg-row--multiple > div .spg-question--location--left {
  height: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  box-sizing: border-box;
}
.spg-theme-builder-root .spg-row--multiple > div .spg-question--location--left .spg-input {
  height: auto;
}
.spg-theme-builder-root .sv-button-group {
  overflow: hidden;
}
.spg-theme-builder-root .spg-question__header--location--left {
  width: max-content;
  flex: 0 0;
}
.spg-theme-builder-root .spg-question__header--location--left .spg-question__title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.spg-theme-builder-root .spg-question__header--location--left .spg-question__title .sv-string-viewer {
  white-space: nowrap;
}
.spg-theme-builder-root .spg-nested-panel {
  padding-bottom: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.spg-theme-builder-root .spg-nested-panel::after {
  content: " ";
  display: block;
  position: relative;
  left: calc(-4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  top: calc(5 * var(--sjs-base-unit, var(--base-unit, 8px)));
  width: calc(8 * var(--sjs-base-unit, var(--base-unit, 8px)) + 100%);
  height: 1px;
  background-color: var(--sjs-border-default, var(--border, #d6d6d6));
}
.spg-theme-builder-root .spg-nested-panel__content > .spg-row:first-of-type {
  margin-top: 0;
}
.spg-theme-builder-root .spg-paneldynamic__panel-wrapper > .spg-nested-panel,
.spg-theme-builder-root .spg-row:last-of-type > div > .spg-nested-panel {
  padding-bottom: 0;
}
.spg-theme-builder-root .spg-paneldynamic__panel-wrapper > .spg-nested-panel::after,
.spg-theme-builder-root .spg-row:last-of-type > div > .spg-nested-panel::after {
  content: none;
}
.spg-theme-builder-root .spg-theme-group-caption {
  --default-bold-font-size: var(--sjs-font-size, calc(2 * var(--sjs-base-unit, var(--base-unit, 8px))));
  font-family: var(--sjs-font-family, var(--font-family));
  font-style: normal;
  font-weight: 600;
  font-size: var(--sjs-font-size, calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))));
  line-height: calc(1.5 * var(--default-bold-font-size));
  color: var(--sjs-general-dim-forecolor, rgba(0, 0, 0, 0.91));
  position: relative;
  top: calc(4 * var(--sjs-base-unit, var(--base-unit, 8px)));
  padding: calc(1 * var(--sjs-base-unit, var(--base-unit, 8px))) 0;
  cursor: auto;
}
.spg-theme-builder-root .sv-theme-group_title-action .spg-action-button:hover,
.spg-theme-builder-root .sv-theme-group_title-action .spg-action-button:focus {
  background-color: unset;
}
.spg-theme-builder-root .sd-panel__header.sd-element__header .spg-action-bar {
  pointer-events: none;
}
.spg-theme-builder-root .spg-header {
  margin-top: calc(-2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.spg-theme-builder-root .sd-question--title-top .spg-paneldynamic {
  margin-top: calc(-1 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.spg-theme-builder-root .spg-question.spg-row__question:not(.sd-question--title-top):not(.spg-question--boolean) {
  margin-top: calc(-1 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.spg-theme-builder-root .spg-row--multiple .spg-question.spg-row__question:not(.sd-question--title-top) {
  margin-top: 0;
}
.spg-theme-builder-root .spg-question__content-coloralpha {
  margin-top: calc(-1 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.spg-theme-builder-root .spg-question-composite__content .spg-row:first-of-type {
  margin-top: var(--sjs-base-unit, var(--base-unit, 8px));
}

.sv-mobile-side-bar .spg-theme-builder-root .spg-nested-panel__content::after {
  width: calc(4 * var(--sjs-base-unit, var(--base-unit, 8px)) + 100%);
  left: calc(-2 * var(--sjs-base-unit, var(--base-unit, 8px)));
}

.spg-boolean-wrapper--overriding.spg-boolean-wrapper--overriding {
  min-width: 0;
  flex: 0 0 max-content;
}

.spg-link-wrapper--overriding {
  align-self: end;
}
.spg-link-wrapper--overriding .svc-action-button {
  text-decoration: underline;
  padding: 0;
  border: none;
  margin: 0;
}
.spg-link-wrapper--overriding .svc-action-button:focus, .spg-link-wrapper--overriding .svc-action-button:hover {
  background-color: transparent;
}

.spg-editor--highlighted .spg-input,
.spg-editor--highlighted .spg-input-container,
.spg-editor--highlighted .spg-question--location--left,
.spg-editor--highlighted .spg-table__cell:not(.spg-table__cell--detail-panel) .spg-input {
  box-shadow: 0 0 0 2px var(--sjs-secondary-backcolor, var(--secondary, #ff9814));
}
.spg-editor--highlighted .spg-checkbox__control:focus + .spg-checkbox__rectangle,
.spg-editor--highlighted .spg-matrixdynamic__content.spg-text__content {
  outline: 2px solid var(--sjs-secondary-backcolor, var(--secondary, #ff9814));
  outline-offset: -2px;
}
.spg-editor--highlighted .spg-checkbox__control:focus + .spg-checkbox__rectangle .spg-input,
.spg-editor--highlighted .spg-matrixdynamic__content.spg-text__content .spg-input {
  box-shadow: none;
}
.spg-editor--highlighted .svc-action-button {
  background-color: var(--sjs-secondary-backcolor-light, var(--secondary-light, rgba(255, 152, 20, 0.25)));
}
.spg-editor--highlighted .sv-button-group {
  box-shadow: 0 0 0 1px var(--sjs-secondary-backcolor, var(--secondary, #ff9814));
  border-color: var(--sjs-secondary-backcolor, var(--secondary, #ff9814));
}
.spg-editor--highlighted .spg-checkbox__control + .spg-checkbox__rectangle {
  outline: 2px solid var(--sjs-secondary-backcolor, var(--secondary, #ff9814));
  outline-offset: -2px;
}

.spg-search-editor_container {
  --sjs-general-forecolor: rgba(0, 0, 0, 0.91);
  --sjs-general-forecolor-light: rgba(0, 0, 0, 0.45);
}

.spg-search-editor_container {
  position: absolute;
  border-bottom: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
  background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  padding: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  width: calc(100% - 4 * var(--sjs-base-unit, var(--base-unit, 8px)));
  display: flex;
  align-items: center;
}

.spg-search-editor_input {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  font-family: var(--sjs-font-family, var(--font-family));
  font-size: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  font-style: normal;
  font-weight: 600;
  line-height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  background: transparent;
  box-sizing: border-box;
  width: 100%;
  outline: none;
  padding-inline-start: var(--sjs-base-unit, var(--base-unit, 8px));
  padding-inline-end: var(--sjs-base-unit, var(--base-unit, 8px));
}

.svc-creator--mobile .spg-search-editor_input {
  font-size: max(16px, calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))));
}

.spg-search-editor_input::placeholder {
  color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}

.spg-search-editor_toolbar {
  display: flex;
  gap: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
}

.spg-search-editor_toolbar .sv-action-bar {
  gap: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin: 0;
  flex-shrink: 0;
}

.spg-search-editor_toolbar .sv-action--hidden {
  display: none;
}

.spg-search-editor_toolbar .spg-search-editor_bar-item {
  padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: min-content;
  margin: 0;
}

.spg-search-editor_toolbar-counter {
  color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  font-family: var(--sjs-font-family, var(--font-family));
  font-style: normal;
  font-weight: 600;
  font-size: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  line-height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  display: flex;
  flex-shrink: 0;
}

.spg-search-editor_search-icon {
  width: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.spg-search-editor_search-icon .sv-svg-icon {
  width: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.spg-search-editor_search-icon .sv-svg-icon use {
  fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}

.spg-root-modern {
  position: relative;
  width: 100%;
  border-right: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
  box-sizing: border-box;
}
.spg-root-modern .sd-row--fade-out,
.spg-root-modern .sd-row--fade-in {
  animation: none;
}
.spg-root-modern .sd-element-wrapper--fade-out,
.spg-root-modern .sd-element-wrapper--fade-in {
  animation: none;
}
.spg-root-modern .sd-element__content--fade-in,
.spg-root-modern .sd-element__content--fade-out {
  animation: none;
}
.spg-root-modern .sd-paneldynamic__panel-wrapper--fade-in,
.spg-root-modern .sd-paneldynamic__panel-wrapper--fade-out,
.spg-root-modern .sd-paneldynamic__panel-wrapper--fade-out-left,
.spg-root-modern .sd-paneldynamic__panel-wrapper--fade-out-right,
.spg-root-modern .sd-paneldynamic__panel-wrapper--fade-in-left,
.spg-root-modern .sd-paneldynamic__panel-wrapper--fade-in-right {
  animation: none;
}
.spg-root-modern .sd-table__row--fade-out,
.spg-root-modern .sd-table__row--fade-in {
  animation: none;
}

.spg-container {
  width: 100%;
}
.spg-container * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.spg-container.spg-container_search {
  height: 100%;
}

.spg-container_search .spg-root-modern {
  top: calc(7 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin-top: 1px;
  height: calc(100% - 1px - 7 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  overflow: auto;
}

.sv-popup--modal.sv-property-editor .sv-popup__container .sv-popup_shadow {
  background-color: transparent;
}
.sv-popup--modal.sv-property-editor .sv-popup__container .sd-root-modern {
  background-color: transparent;
}
.sv-popup--modal.sv-property-editor .sv-popup__container .sd-page {
  margin: 0;
  padding: 0;
}
.sv-popup--modal.sv-property-editor .sv-popup__container .sv-components-column--expandable {
  min-width: calc(78 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv-popup--modal .sv-popup__footer {
  padding-bottom: 2px;
}
.sv-popup--modal .sd-body,
.sv-popup--modal .sl-body {
  min-width: calc(78 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-popup.sv-property-editor .sd-root-modern {
  --sd-mobile-width: 0;
}

.svc-object-selector .sv-popup__container {
  margin-left: var(--sjs-base-unit, var(--base-unit, 8px));
  margin-right: var(--sjs-base-unit, var(--base-unit, 8px));
}

.svc-object-selector__content {
  position: relative;
  height: 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
}

.spg-title .spg-action-bar {
  margin-top: calc(-1 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin-bottom: calc(-1 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin-right: 1px;
}

.spg-body {
  width: 100%;
}

.spg-body--empty {
  margin: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.spg-title-toolobar--single-help-action {
  justify-content: flex-start;
  padding-left: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.spg-title-toolobar--single-help-action .spg-help-action {
  flex-grow: 1;
}
.spg-title-toolobar--single-help-action .spg-help-action .spg-action-button--icon {
  opacity: 0.5;
  padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.spg-title-toolobar--single-help-action .spg-help-action .spg-action-button--icon:focus,
.spg-title-toolobar--single-help-action .spg-help-action .spg-action-button--icon:hover {
  opacity: 1;
}
.spg-title-toolobar--single-help-action .spg-help-action .spg-action-button:active {
  opacity: 0.5;
}
.spg-title-toolobar--single-help-action .spg-help-action svg {
  fill: var(--sjs-general-dim-forecolor-light, rgba(0, 0, 0, 0.45));
}