.activeUsers-table {
  border: none;
  tr {
    border: none;
  }
  thead {
    tr {
      th {
        background: transparent !important;
        color: #2c3236;
        font-family: Overpass;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        text-transform: capitalize;
        &:first-child {
          text-align: left;
        }
        &:last-child {
          text-align: right;
        }
        padding-bottom: 26px;
      }
    }
  }

  tbody {
    tr {
      td {
        color: #313131;
        font-family: Overpass;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.14px;
        &:first-child {
          text-align: left;
        }
        &:last-child {
          text-align: center;
        }

        padding-bottom: 20px;
      }
    }
  }
}
