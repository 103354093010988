.spg-table-wrapper {
  border: 1px solid $border;
  border-bottom: none;
}

.spg-table {
  width: 100%;
  background-color: $background;
  border-collapse: collapse;

  tr {
    border-bottom: 1px solid $border;
  }
}

.spg-table__cell {
  padding: 0;

  .spg-checkbox {
    margin: 0 calcSize(2);
  }
}

.spg-table__cell--detail-panel {
  .spg-panel__content {
    box-shadow: none;
  }

  background: $background-dim;
}

.spg-table__cell:not(.spg-table__cell--detail-panel):not(.spg-table__cell--actions):first-of-type {
  padding-left: 8px;
}

.spg-table__cell:not(.spg-table__cell--detail-panel) .spg-input {
  appearance: none;
  line-height: calcSize(3);
  font-size: calcSize(2);
  box-shadow: none;
  border-radius: calcSize(0.5);
  background-color: transparent;
  padding: calcSize(1);
  height: auto;

  &[type="color"] {
    padding-right: 0;
  }

  &:focus,
  &:focus-within {
    box-shadow: inset 0 0 0 2px $primary;
  }
}

.spg-table__cell:not(.spg-table__cell--detail-panel) .spg-dropdown_chevron-button {
  display: none;
}

.spg-table__cell:not(.spg-table__cell--detail-panel) .spg-dropdown {
  width: max-content;
  padding-right: calcSize(4);
  background-size: calcSize(3) calcSize(3);
  background-position: right calcSize(0.5) top 50%, 0 0;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 15L17 10H7L12 15Z' fill='%2390909080'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
}

.spg-table__cell:not(.spg-table__cell--detail-panel):first-of-type .spg-text {
  padding-left: calcSize(2);
}

.spg-table__cell--actions:first-of-type {
  width: calcSize(5);
}

.spg-table__cell--actions:last-of-type {
  .spg-action-bar {
    justify-content: flex-end;
  }
}

.spg-table__cell--actions>.spg-matrixdynamic__drag-element {
  display: inline-block;
  margin-top: calcSize(0.5);
  cursor: move;
}

.spg-table__cell--header {
  font-size: calcSize(1.5);
  font-weight: normal;
  color: $foreground-light;
  line-height: calcSize(3);
  background: $background-dim;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100px;

  span {
    display: none;
  }

  .sv-string-viewer {
    display: inline;
    white-space: unset;
  }
}

.spg-table__question-wrapper {
  padding: calcSize(0.5) 0;
}